<template>
  <b-autocomplete
    :id="id"
    v-model="model"
    :append-to-body="true"
    autocomplete="false"
    :data="filteredCountries"
    :expanded="expanded"
    field="name"
    keep-first
    :required="required"
    :size="size"
    @select="$emit('input', $event ? $event.code : null)"
  >
    <template v-slot="{ option }">
      <option :id="`${id}-country-${option.code}`">{{ option.name }}</option>
    </template>
    <template #empty>Land niet gevonden</template>
  </b-autocomplete>
</template>

<script>
  import { BCheckbox } from 'buefy/src/components/checkbox';
  import * as countries from '@/utils/countries';

  export default {
    name: 'checkbox',
    extends: BCheckbox,
    props: {
      value: String,
      id: {
        type: String,
        default: 'country-code-autocomplete',
      },
      expanded: {
        type: Boolean,
        default: false,
      },
      size: String,
      required: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        model: this.sanitizeValue(this.value),
      };
    },
    computed: {
      countries() {
        return Object.entries(countries.getNames('nl')).map(country => ({ code: country[0], name: country[1] }));
      },
      filteredCountries() {
        return this.countries.filter(country => country.name.toLowerCase().includes(this.model.toLowerCase()));
      },
    },
    methods: {
      sanitizeValue(value) {
        return countries.getName(value, 'nl') || ''; // Autocomplete breaks if this.model === undefined
      },
    },
    watch: {
      value(newVal) {
        this.model = this.sanitizeValue(newVal);
      },
    },
  };
</script>
