<template>
  <error-details>
    <p>{{ message || $t('errors.default') }}</p>
    <p v-if="isQuotaExceeded">
      <b-button @click="closeAndNavigate({ name: 'plans' })">Naar abonnementen</b-button>
    </p>
    <template v-if="details" #details>
      <div v-if="details !== true" class="properties">
        <div class="property">
          <div class="name">Error</div>
          <div v-if="error.message" class="value">{{ error.message }}</div>
          <div v-else class="value">API error</div>
        </div>
        <div class="property">
          <div class="name">error.URL</div>
          <div class="value">{{ error.url }}</div>
        </div>
        <div class="property">
          <div class="name">Response</div>
          <div v-if="Array.isArray(details)" class="value">
            <pre><div v-for="(item, idx) in details" :key="idx">{{ item }}</div></pre>
          </div>
          <div v-else class="value">
            <!-- Assume details is a string -->
            <pre>{{ details }}</pre>
          </div>
        </div>
      </div>
      <slot name="details"></slot>
    </template>
  </error-details>
</template>

<script>
  import ErrorDetails from './ErrorDetails';
  export default {
    components: { ErrorDetails },
    props: {
      error: Object
    },
    computed: {
      message() {
        if (this.error?.response?.message_code) {
          if (this.error?.response?.status === 'error') {
            if (this.error?.response?.type)
              return this.$t(
                `errors.${this.error?.response?.type}.${this.error?.response?.message_code}`,
                this.error?.response?.fields || {}
              );
            return this.$t(`errors.${this.error?.response?.message_code}`, this.error?.response?.fields || {});
          }
        }
        return this.$t('errors.default');
      },
      details() {
        if (typeof this.error?.response === 'string') return this.error?.response;
        if (this.error?.response && (!this.error?.response?.message_code || this.error?.response?.status !== 'error'))
          return JSON.stringify(this.error?.response);
        if (this.error?.response?.fields?.details) return this.error?.response?.fields?.details;
        // This check needs to happen last, otherwise non-slotted details will disappear
        if (this.$slots.details) return true;
        return null;
      },
      isQuotaExceeded() {
        return this.error?.response?.message_code === 'quota_exceeded';
      }
    },
    methods: {
      closeAndNavigate(to) {
        this.$emit('close');
        this.$router.push(to);
      }
    }
  };
</script>
