import { api } from '@/utils';

const state = {
  all: [],
  isLoading: false,
  hasLoadedOnce: false
};

const getters = {};

const actions = {
  ensureData({ state, dispatch }) {
    if (!state.hasLoadedOnce) dispatch('getInvoices');
  },
  async getInvoices({ commit }) {
    commit('setIsLoading', true);
    const result = await api.getInvoices();
    if (result.status === 'success') commit('setInvoices', result.data);
  },
  async getPdf(_, invoice) {
    const result = await api.getInvoicePdf(invoice.id);
    // TODO: Handle errors
    if (result.file) return result.file;
  },
  async getCsv(_, invoice) {
    const result = await api.getInvoiceCsv(invoice.id);
    // TODO: Handle errors
    if (result.file) return result.file;
  }
};

const mutations = {
  setInvoices(state, invoices) {
    state.all = invoices;
    state.isLoading = false;
    state.hasLoadedOnce = true;
  },
  setIsLoading(state, isLoading) {
    state.isLoading = isLoading;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
