<script>
  export default {
    props: {
      onConfirm: {
        type: Function,
        required: true,
      },
    },
    data() {
      return {
        toTransfer: {
          shouldTransferOrderNumber: false,
          shouldTransferShipmentSettings: false,
        },
      };
    },
    methods: {
      updateLocalStorage() {
        localStorage.setItem('orderTransferSettings', JSON.stringify(this.toTransfer));
      },

      confirm() {
        this.onConfirm(this.toTransfer);
        this.$parent.close();
      },
      cancel() {
        this.$parent.onCancel();
        this.$parent.close();
      },
    },

    mounted() {
      const settingsFromLocalStorage = JSON.parse(localStorage.getItem('orderTransferSettings'));

      if (settingsFromLocalStorage) {
        this.toTransfer.shouldTransferOrderNumber = settingsFromLocalStorage.shouldTransferOrderNumber;
        this.toTransfer.shouldTransferShipmentSettings = settingsFromLocalStorage.shouldTransferShipmentSettings;
      }
    },

    name: 'TransferModal',
  };
</script>

<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <p class="modal-card-title">Label dupliceren</p>
      <button class="delete" type="button" @click="cancel" />
    </header>
    <section class="modal-card-body">
      <div class="content">
        <p>
          Je staat op het punt om een label te dupliceren.<br />
          Je kunt kiezen welke gegevens je wilt overnemen van het originele label.
        </p>
      </div>
      <b-field>
        <b-switch v-model="toTransfer.shouldTransferOrderNumber" @input="updateLocalStorage">Ordernummer</b-switch>
      </b-field>
      <b-field>
        <b-switch v-model="toTransfer.shouldTransferShipmentSettings" @input="updateLocalStorage"
          >Verzendinstellingen</b-switch
        >
      </b-field>
    </section>
    <footer class="modal-card-foot is-justify-content-space-between">
      <b-button @click="cancel">Annuleren</b-button>
      <b-button type="is-primary" @click="confirm">Label dupliceren</b-button>
    </footer>
  </div>
</template>

<style scoped lang="scss"></style>
