<template>
  <b-notification
    aria-close-label="Close notification"
    has-icon
    role="alert"
    :type="type === 'is-info' ? type + ' is-light' : type"
  >
    <div class="content">
      <!-- TODO: Slot? -->
      <p>
        {{ bannerText }}
      </p>
      <p>
        <!-- TODO: Make router link -->
        <a
          v-if="buttonText"
          :class="type !== 'is-info' ? 'button is-light ' + type : 'button ' + type"
          v-bind:href="href"
          outlined
          :target="external === true ? '_blank' : false"
        >
          {{ buttonText }}
        </a>
      </p>
    </div>
  </b-notification>
</template>

<script>
  export default {
    props: {
      bannerText: String,
      buttonText: String,
      href: String,
      type: String,
      external: {
        type: Boolean,
        default: false
      }
    }
  };
</script>
