import Vue from 'vue';
import Buefy from 'buefy';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import filters from '@/utils/filters';
import TemplateWith from '@/components/TemplateWith';
import { initKeycloak } from '@/utils/keycloak';
import { reportError } from '@/utils/errors';
import i18n from '@/i18n';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import * as FONT_AWESOME_ICONS from '@/constants/FONT_AWESOME_ICONS';

/** @type{import('buefy/types/components').BuefyConfig} */
const buefyConfig = {
  defaultIconPack: 'gg',
  customIconPacks: {
    gg: {
      sizes: {
        'default': 'is-size-5',
        'is-small': '',
        'is-medium': 'is-size-3',
        'is-large': 'is-size-1'
      },
      iconPrefix: 'gg-',
      internalIcons: {
        'check': 'check',
        'information': 'info',
        'check-circle': 'check-o',
        'alert': 'danger',
        'alert-circle': 'danger',
        'arrow-up': 'arrow-up',
        'chevron-right': 'chevron-right',
        'chevron-left': 'chevron-left',
        'chevron-down': 'arrow-down',
        'eye': 'eye',
        'eye-off': 'eye',
        'menu-down': 'chevron-down',
        'menu-up': 'chevron-up',
        'close-circle': 'close-o'
      }
    }
  }
};

if (process.env.VUE_APP_SENTRY_DSN) {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    tunnel: process.env.VUE_APP_SENTRY_TUNNEL,
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.0
  });
}

// window.addEventListener('error', function(event) { console.log('blah', event); });

window.addEventListener('unhandledrejection', function (event) {
  reportError(event.reason);

  // Don't log to console. Doen't work in Firefox: https://bugzilla.mozilla.org/show_bug.cgi?id=1642147
  event.preventDefault();
});

Vue.config.errorHandler = function (error) {
  reportError(error);
};

Vue.config.productionTip = false;

Vue.use(Buefy, buefyConfig);
Vue.use(filters);
Vue.component('v-with', TemplateWith);

library.add(...Object.values(FONT_AWESOME_ICONS));
Vue.component('font-awesome-icon', FontAwesomeIcon);

initKeycloak().then(() => {
  // window.innosendApp is used in reportError
  window.innosendApp = new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
  }).$mount('#app');
});
