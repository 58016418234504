import { api } from '@/utils';

const state = {
  organisation: null,
  user: null,
  labelCount: null,
  ownCouriers: null,
  proxyCouriers: null
};

const getters = {
  mailSettings: state => state.organisation.tracking.mail_settings
};

const actions = {
  async init({ dispatch }) {
    await dispatch('getUser');
    await dispatch('getLabelCount');
    await dispatch('getCourierCount');
  },
  async getUser({ commit }) {
    const user = await api.getUser();
    commit('setUser', user);
    if (user.organisation) commit('setOrganisation', user.organisation);
  },
  async updateOrganisation({ commit, dispatch }, updates) {
    const result = await api.updateUserOrganisation(updates);
    if (result.status === 'success') {
      if (result.should_validate_orders) {
        dispatch('order/validateOrders', result.data.id, { root: true });
      }
      commit('setOrganisation', result.data);
    }
    return result;
  },
  async updateOrganisationPlan({ commit }, payload) {
    const result = await api.subscribePlan(payload);
    if (result.status === 'success') commit('setOrganisation', result.data);
    return result;
  },
  async createPayment({ commit }) {
    const result = await api.createPayment();
    if (result.status === 'success') commit('setOrganisation', result.data);
    return result;
  },
  async deletePayment({ commit }) {
    const result = await api.deletePayment();
    if (result.status === 'success') commit('setOrganisation', result.data);
    return result;
  },
  async getLabelCount({ commit }) {
    try {
      const labelCounters = await api.getLabelCounters();
      if (labelCounters.status && labelCounters.status == 'success') {
        commit('setLabelCount', labelCounters.data.labels);
      }
    } catch (error) {
      // Probably no current plan, ignore errors for now
      // TODO: Handle errors properly
    }
  },
  async getCourierCount({ commit }) {
    let courierCounters;
    try {
      courierCounters = await api.getCourierCounters();
      if (courierCounters.status && courierCounters.status == 'success') {
        commit('setOwnCourierCount', courierCounters.data.own_couriers);
        commit('setProxyCourierCount', courierCounters.data.proxy_couriers);
      }
    } catch (error) {
      // Probably no current plan, ignore errors for now
      // TODO: Handle errors properly
    }
  },

  // TODO: name could be better
  async setTracking({ commit }, action) {
    const result = await api.setTracking({ action });
    if (result.status === 'success') commit('setOrganisation', result.data);
    return result;
  },

  async updateTracking({ commit }, updates) {
    const result = await api.updateTracking(updates);
    if (result.status === 'success') commit('setOrganisation', result.data);
    return result;
  },

  async setReturn({ commit }, action) {
    const result = await api.setReturn(action);
    if (result.status === 'success') commit('setOrganisation', result.data);
    return result;
  },

  updateSubscriptionPeriod({ commit }, period) {
    commit('setSubscriptionPeriod', period);
  }
};

const mutations = {
  setFirstLogin(state, firstLogin) {
    state.user.is_first_login = firstLogin;
  },
  setOrganisation(state, organisation) {
    state.organisation = organisation;
  },
  setUser(state, user) {
    state.user = user;
  },
  setOwnCourierCount(state, count) {
    state.ownCouriers = Number(count);
  },
  setProxyCourierCount(state, count) {
    state.proxyCouriers = Number(count);
  },
  setLabelCount(state, count) {
    state.labelCount = Number(count);
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
