import { api } from '@/utils';

const state = {
  all: []
};

const actions = {
  init({ dispatch }) {
    dispatch('getPlans');
  },
  async getPlans({ commit }) {
    const plans = await api.getPlans();
    commit('setPlans', plans);
  }
};

const mutations = {
  setPlans(state, plans) {
    state.all = plans;
  }
};

export default {
  state,
  actions,
  mutations
};
