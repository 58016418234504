<template>
  <label
    ref="label"
    class="b-checkbox checkbox"
    :class="[size, { 'is-disabled': disabled }]"
    :disabled="disabled"
    :for="componentId"
    @click="focus"
    @keydown.prevent.enter="$refs.label.click()"
  >
    <input
      :id="componentId"
      ref="input"
      v-model="computedValue"
      :disabled="disabled"
      :false-value="falseValue"
      :indeterminate.prop="indeterminate"
      :name="name"
      :required="required"
      :true-value="trueValue"
      type="checkbox"
      :value="nativeValue"
      @click.stop
    />
    <span class="check" :class="type" />
    <span class="control-label" :class="{ 'is-sr-only': labelHidden }"><slot /></span>
  </label>
</template>

<script>
  import { BCheckbox } from 'buefy/src/components/checkbox';

  export default {
    name: 'checkbox',
    extends: BCheckbox,
    props: {
      id: String,
      labelHidden: Boolean
    },
    computed: {
      componentId() {
        if (this.id) return this.id;
        return `checkbox_${this._uid}`;
      }
    }
  };
</script>
