import * as countries from 'i18n-iso-countries';

countries.registerLocale(require('i18n-iso-countries/langs/nl.json'));

const EXTENDED_COUNTRIES = {
  'IC': 'Canarische Eilanden',
};

export function getName(code, lang, options = {}) {
  if (code && EXTENDED_COUNTRIES[code.toUpperCase()])
    return EXTENDED_COUNTRIES[code.toUpperCase()];
  return countries.getName(code, lang, options);
}

export function getNames(lang, options = {}) {
  return Object.assign(
    countries.getNames(lang, options),
    EXTENDED_COUNTRIES,
  );
}
