<template>
  <form id="feedback-form" @submit.prevent="submit">
    <div class="modal-card is-wide">
      <header class="modal-card-head">
        <p class="modal-card-title">Feedback</p>
        <button aria-label="close" class="delete" @click="$parent.close()"></button>
      </header>
      <section class="modal-card-body">
        <div class="content">
          <p>Help Innosend te verbeteren door meer informatie te geven over deze foutmelding.</p>
        </div>
        <b-field label="Naam">
          <b-input v-model="fields.name" required></b-input>
        </b-field>
        <b-field label="E-mailadres">
          <b-input v-model="fields.email" required type="email"></b-input>
        </b-field>
        <b-field label="Wat gebeurde er? Beschrijf het zo gedetailleerd mogelijk.">
          <b-input v-model="fields.comments" maxlength="2048" required type="textarea"></b-input>
        </b-field>
      </section>
      <footer class="modal-card-foot buttons is-right">
        <button class="button" type="button" @click="$parent.close()">Annuleren</button>
        <button class="button is-primary" form="feedback-form" type="submit">Feedback verzenden</button>
      </footer>
    </div>
  </form>
</template>

<script>
  export default {
    props: {
      event_id: String,
    },
    data() {
      const organisation = this.$store.state.account.organisation;
      let fields = {
        name: organisation?.contact?.name,
        email: organisation?.contact?.email,
        comments: null,
      };
      return {
        fields,
      };
    },
    methods: {
      async submit() {
        const url = process.env.VUE_APP_SENTRY_TUNNEL + '/feedback';
        const body = {
          dsn: process.env.VUE_APP_SENTRY_DSN,
          project: 'innnosend-dashboard', // TODO: unhardcode
          feedback: {
            event_id: this.event_id,
            ...this.fields,
          },
        };
        const init = {
          method: 'POST',
          body: JSON.stringify(body),
          headers: new Headers({ 'Content-Type': 'application/json' }),
        };
        try {
          const response = await fetch(url, init);
          if (!response.ok) throw new Error();
          this.$buefy.toast.open({
            message: 'Je feedback is verzonden. Dankjewel!',
            type: 'is-success',
            duration: 5000,
          });
        } catch (e) {
          this.$buefy.toast.open({
            message: 'Fout bij het verzenden van de feedback. Probeer het nog eens.',
            type: 'is-danger',
            duration: 5000,
          });
        } finally {
          this.$parent.close();
        }
      },
    },
  };
</script>
