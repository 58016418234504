<template>
  <main class="form-page">
    <div class="section">
      <header class="container block">
        <!-- I'm not too fond of this header, but it works for now... -->
        <nav class="block">
          <b-button icon-left="arrow-left" @click="$router.backOrDefault(defaultReturnRoute)"> Terug</b-button>
        </nav>
        <div class="level">
          <div class="level-left">
            <h1 id="shipment-title" class="title">Zendingdetails</h1>
          </div>
        </div>
      </header>
      <section class="container">
        <b-loading v-model="isLoading" :is-full-page="false"></b-loading>
        <div class="level">
          <div class="level-left">
            <div class="level-item">
              <print-controls context="label" :label-ids="[label.id]" :order-ids="[label.order_id]"></print-controls>
            </div>
            <div v-if="label.order_items && label.validators && label.validators.is_export" class="level-item">
              <b-button
                :disabled="printExportMutex"
                icon-left="software-download"
                :loading="printExportMutex"
                @click="printExportDoc(label.id, 'pdf')"
              >
                Export PDF
              </b-button>
            </div>
            <div class="level-item">
              <b-tooltip :active="!canCreateReturnLabel" append-to-body multilined type="is-warning">
                <template #content>
                  Je mag voor
                  <span class="has-text-weight-bold">{{ courier?.service_name }}</span> bestellingen die gaan naar
                  <span class="has-text-weight-bold">{{ label.country }}</span> geen retourlabels aanmaken.
                </template>
                <b-button
                  :disabled="!canCreateReturnLabel || isCreatingReturnLabel"
                  :loading="isCreatingReturnLabel"
                  @click="createReturnLabel({ order_ids: [label.order_id] })"
                >
                  Retourlabel maken
                </b-button>
              </b-tooltip>
            </div>
            <div class="level-item">
              <b-dropdown append-to-body position="is-bottom-left">
                <template #trigger>
                  <b-button> Meer acties… <font-awesome-icon fixed-width icon="chevron-down" /></b-button>
                </template>

                <b-dropdown-item
                  aria-role="listitem"
                  style="display: flex; align-items: center; gap: 8px"
                  @click="newOrderFromLabel(label)"
                >
                  <font-awesome-icon class="is-size-5" fixed-width icon="copy" />
                  Label dupliceren naar bestellingen
                </b-dropdown-item>
                <b-dropdown-item
                  aria-role="listitem"
                  style="display: flex; align-items: center; gap: 8px"
                  @click="deactivateLabel(label, true)"
                >
                  <font-awesome-icon class="is-size-5" fixed-width icon="trash" />
                  Label verwijderen
                </b-dropdown-item>

                <template v-if="isAllowedToTicket">
                  <b-dropdown-item v-if="!ticket" aria-role="listitem" has-link>
                    <router-link
                      style="display: flex; align-items: center; gap: 8px"
                      :to="{ name: 'create_ticket', params: { labelId: label.id } }"
                    >
                      <font-awesome-icon class="is-size-5" fixed-width icon="bolt-lightning" />
                      <span>Onderzoek aanvragen</span>
                    </router-link>
                  </b-dropdown-item>
                  <b-dropdown-item v-else aria-role="listitem" has-link>
                    <router-link :to="{ name: 'ticket-single', params: { ticketId: label.ticket.ticket_id } }">
                      <font-awesome-icon class="is-size-5" fixed-width icon="bolt-lightning" />
                      <span>Naar onderzoek</span>
                    </router-link>
                  </b-dropdown-item>
                </template>
              </b-dropdown>
            </div>
          </div>
        </div>
        <hr class="separator" />
        <status-info
          v-if="label.status_info"
          container-class="block"
          :info="label.status_info"
          item-class="notification is-danger"
        >
          <template
            v-if="label.status_info?.find(item => item.status === 'warning' && item.type === 'order_update_failed')"
            #action
          >
            <b-button class="is-primary" inverted outlined @click="retry">Probeer opnieuw</b-button>
          </template>
        </status-info>
        <div class="columns">
          <div class="column is-one-third-desktop">
            <h2 class="title is-4">Geschiedenis</h2>
            <ul class="timeline block">
              <li v-for="(activity, index) in history" :key="index" :class="activity.class">
                <p>{{ activity.description }}</p>
                <p v-if="activity.period" class="is-size-7 has-text-grey">
                  <template v-if="deliveryEstimate.start && deliveryEstimate.end">
                    <template
                      v-if="
                        new Date(deliveryEstimate.start).toDateString() == new Date(deliveryEstimate.end).toDateString()
                      "
                    >
                      {{ deliveryEstimate.start | humanizeTimestamp('day') }}
                      tussen
                      {{ deliveryEstimate.start | humanizeTimestamp('time') }}
                      en
                      {{ deliveryEstimate.end | humanizeTimestamp('time') }}
                    </template>
                    <template v-else>
                      tussen
                      {{ deliveryEstimate.start | humanizeTimestamp }}
                      en
                      {{ deliveryEstimate.end | humanizeTimestamp }}
                    </template>
                  </template>
                  <template v-else-if="deliveryEstimate.start">
                    {{ deliveryEstimate.start | humanizeTimestamp('day') }}
                    vanaf
                    {{ deliveryEstimate.start | humanizeTimestamp('time') }}
                  </template>
                  <template v-else-if="deliveryEstimate.end">
                    {{ deliveryEstimate.end | humanizeTimestamp('day') }}
                    voor
                    {{ deliveryEstimate.end | humanizeTimestamp('time') }}
                  </template>
                </p>
                <p v-else class="is-size-7 has-text-grey">
                  {{ activity.last_updated | humanizeTimestamp }}
                </p>
              </li>
            </ul>
            <template v-if="label.invoice_items">
              <b-collapse animation="slide" aria-id="invoice_items_collapse" :open="false">
                <template #trigger="props">
                  <a aria-controls="invoice_items_collapse" :aria-expanded="props.open" class="block">
                    <h2>
                      <b-icon :icon="props.open ? 'chevron-up' : 'chevron-down'"></b-icon>
                      Prijs en facturatie
                    </h2>
                  </a>
                </template>
                <div v-for="(price, idx) in label.invoice_items" :key="'invoice_item' + idx" class="block">
                  <v-with
                    v-slot="{ total }"
                    :total="price.parts.reduce((acc, value) => acc + parseFloat(value.unit_price), 0)"
                  >
                    <table class="table is-striped is-hoverable is-narrow is-fullwidth">
                      <thead>
                        <tr>
                          <th v-if="price.type === 'label_price'" colspan="2">Labelprijs</th>
                          <th v-else-if="price.type === 'surcharge'" colspan="2">Naheffing</th>
                          <th v-else-if="price.type === 'refund'" colspan="2">Terugbetaling</th>
                          <th v-else colspan="2">Onbekend factuurtype</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in price.parts" :key="'invoice_item' + idx + item.id">
                          <td>{{ item.description }}</td>
                          <td class="has-text-right">{{ item.unit_price | formatMoney }}</td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <th>Totaal</th>
                          <th class="has-text-right">{{ total | formatMoney }}</th>
                        </tr>
                      </tfoot>
                    </table>
                  </v-with>
                </div>
              </b-collapse>
            </template>
          </div>
          <div class="column is-two-thirds-desktop">
            <div class="columns is-multiline">
              <div class="column is-full-tablet is-half-desktop">
                <h2 class="title is-4">Ontvanger</h2>
                <div class="properties">
                  <div class="property">
                    <div class="name">Naam</div>
                    <div class="value">{{ label.name }}</div>
                  </div>

                  <div v-if="label.company_name" class="property">
                    <div class="name">Bedrijf</div>
                    <div class="value">{{ label.company_name }}</div>
                  </div>

                  <div class="property">
                    <div class="name">Adres</div>
                    <div class="value">
                      <address>
                        {{ label.address_1 }} {{ label.housenumber }}<br />
                        <template v-if="label.address_2">{{ label.address_2 }}<br /></template>
                        {{ label.zipcode }} {{ label.city }}<br />
                        <country v-model="label.country" />
                      </address>
                    </div>
                  </div>

                  <div v-if="label.email" class="property">
                    <div class="name">E-mail</div>
                    <div class="value">
                      <a :href="`mailto:${label.email}`">{{ label.email }}</a>
                    </div>
                  </div>

                  <div v-if="label.phone" class="property">
                    <div class="name">Telefoonnummer</div>
                    <div class="value">{{ label.phone }}</div>
                  </div>
                </div>
                <template v-if="cameFromReturn && !!label.reason_retour">
                  <h2 class="title is-4">Retour redenen</h2>
                  <ul class="tw-list-disc tw-list-inside">
                    <template v-for="(value, key) in label.reason_retour">
                      <li v-if="value" :key="key">
                        {{ REASON_MAPPING[key] }}<template v-if="typeof value === 'string'">: {{ value }}</template>
                      </li>
                    </template>
                  </ul>
                </template>
              </div>
              <div class="column is-full-tablet is-half-desktop">
                <h2 class="title is-4">Zending</h2>
                <div class="properties">
                  <div class="property">
                    <p class="name">Verzendmethode</p>
                    <p class="value">
                      <courier :item="label" :show-warnings="false" />
                    </p>
                  </div>

                  <div class="property">
                    <p class="name">Status</p>
                    <p class="value">
                      <status :item="label" :no-class="true"></status>
                    </p>
                  </div>

                  <div v-if="label.shipment && label.shipment.tracking_url" class="property">
                    <p class="name">Track zending</p>
                    <p class="value">
                      <tracking-number
                        :tracking-number="label.shipment.tracking_number"
                        :tracking-url="label.shipment.tracking_url"
                      />
                    </p>
                  </div>

                  <!-- TODO: Find a better solution for these nested properties. They don't look right -->
                  <div
                    v-if="
                      label &&
                      label.shipment &&
                      label.shipment.courier_fields &&
                      Object.keys(label.shipment.courier_fields).length > 0 &&
                      courierMeta
                    "
                    class="property"
                  >
                    <p class="name">Gekozen verzendopties</p>
                    <div v-if="courierFields.length" class="value properties">
                      <div v-for="field in courierFields" :key="field.id" class="property">
                        <div v-if="field.props" class="name">
                          {{ field.props.label }}
                        </div>
                        <div v-else-if="field.name" class="name is-capitalized">
                          {{ field.name }}
                        </div>
                        <div v-else class="name is-capitalized">
                          {{ field.id }}
                        </div>
                        <div class="value">
                          <v-with
                            v-if="field.props && field.props.type === 'select'"
                            v-slot="{ option }"
                            :option="field.props.options.find(f => f.value == field.value)"
                          >
                            <span>
                              <template v-if="option">
                                {{ option.label }}
                              </template>
                              <template v-else>
                                {{ field.value | formatValue }}
                              </template>
                            </span>
                          </v-with>
                          <template v-else-if="field.props && field.props.type !== 'hidden'">
                            {{ field.value | formatValue }}
                          </template>
                        </div>
                      </div>
                    </div>
                    <div v-else class="value">Geen verzendopties gekozen</div>
                  </div>
                </div>

                <template v-if="label.shipment">
                  <div v-for="(package_, idx) in label.shipment.packages" :key="`package-${idx}`" class="properties">
                    <h3 class="title is-5">
                      Pakket
                      <span v-if="label.shipment.packages.length > 1">{{ idx + 1 }}</span>
                    </h3>

                    <div class="property">
                      <p class="name">Gewicht</p>
                      <p v-if="package_.weight" class="value">{{ package_.weight }} gram</p>
                      <p v-else class="value">–</p>
                    </div>
                    <div v-if="package_.tracking_url" class="property">
                      <p class="name">Tracking</p>
                      <tracking-number
                        class="value"
                        :tracking-number="package_.tracking_number"
                        :tracking-url="package_.tracking_url"
                      />
                    </div>
                  </div>
                </template>

                <h2 class="title is-4">Order</h2>
                <div class="properties">
                  <div v-if="label.order_number" class="property">
                    <div class="name">Ordernummer</div>
                    <div class="value">{{ label.order_number }}</div>
                  </div>

                  <div v-if="label.order_date" class="property">
                    <div class="name">Orderdatum</div>
                    <div class="value">{{ label.order_date | formatTimestamp }}</div>
                  </div>

                  <div v-if="label.order_status" class="property">
                    <div class="name">Shop Status</div>
                    <div class="value">{{ label.order_status }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="order_items && order_items.length > 0">
          <hr class="seperator" />
          <h2 class="title is-4">
            {{ cameFromReturn ? 'Geretourneerde producten' : 'Orderregels' }}
          </h2>

          <div v-if="order_items.some(item => item.is_dummy_item)">
            <b-notification aria-close-label="Close notification" :closable="false" type="is-info">
              Innosend heeft voor deze order fictieve gegevens toegevoegd om te voldoen aan de minimale
              productinformatie-eisen van Fedex.
            </b-notification>
          </div>

          <b-table :data="order_items" hoverable mobile-cards striped>
            <b-table-column v-slot="props" field="name" label="Naam">
              {{ props.row.name }}
            </b-table-column>
            <b-table-column v-slot="props" field="description" label="Omschrijving">
              {{ props.row.description }}
            </b-table-column>
            <b-table-column v-slot="props" field="quantity" label="Aantal" numeric>
              {{ props.row.quantity }}
            </b-table-column>
            <b-table-column v-slot="props" field="unit_price_inc_btw" label="Prijs per product" numeric>
              {{ props.row.unit_price_inc_btw | formatMoney(label.currency || 'EUR') }}
            </b-table-column>
            <b-table-column v-slot="props" field="weight" label="Gewicht per product" numeric>
              {{ props.row.weight | humanizeUnit('gram') }}
            </b-table-column>
            <b-table-column v-slot="props" field="category" label="Categorie">
              {{ props.row.category }}
            </b-table-column>
            <b-table-column v-slot="props" field="sku" label="SKU-code" numeric>
              {{ props.row.sku }}
            </b-table-column>
            <b-table-column v-slot="props" field="hs_code" label="HS-code" numeric>
              {{ props.row.hs_code }}
            </b-table-column>
            <b-table-column v-slot="props" field="product_country" label="Land van herkomst">
              <country :value="props.row.product_country" />
            </b-table-column>
          </b-table>
        </div>
      </section>
    </div>
    <form-page-footer>
      <div class="container buttons is-right">
        <b-button type="is-primary" @click="$router.backOrDefault(defaultReturnRoute)"> OK</b-button>
      </div>
    </form-page-footer>
  </main>
</template>

<script>
  import { mapState } from 'vuex';

  import PrintControls from '@/components/PrintControls.vue';
  import FormPageFooter from '@/components/form/FormPageFooter';
  import LabelMixin from '@/components/mixins/LabelMixin.js';
  import Country from '@/components/properties/Country';
  import Courier from '@/components/properties/Courier';
  import Status from '@/components/properties/Status';
  import TrackingNumber from '@/components/properties/TrackingNumber';
  import StatusInfo from '@/components/properties/StatusInfo';
  import { download, getMoment } from '@/utils/functions';
  import { ApiError } from '@/utils/errors';
  import { REASON_MAPPING } from '../constants/REASON_MAPPING';

  export default {
    components: { Country, Courier, FormPageFooter, PrintControls, Status, StatusInfo, TrackingNumber },
    mixins: [LabelMixin],
    data() {
      return {
        labelId: this.$route.params.labelId,
        isLoading: false,
        printExportMutex: false,
        label: {},
        defaultReturnRoute: { name: 'dashboard', params: { activeTabName: 'labels' } },
      };
    },

    computed: {
      REASON_MAPPING() {
        return REASON_MAPPING;
      },
      ...mapState({
        organisation: state => state.account.organisation,
      }),

      cameFromReturn() {
        return this.$route.query?.is_returned === '1';
      },
      order_items() {
        return this.label.order_items?.filter(item => (!this.cameFromReturn ? item : !!item.is_returned));
      },
      courier() {
        return this.$store.getters['courier/courierById'](this.label.courier_id || this.label.service_id);
      },
      isCourierProxied() {
        return this.courier?.is_proxy === true;
      },
      isCourierInvoiced() {
        return this.courier?.is_invoiced === true;
      },
      courierMeta() {
        return this.$store.state.courier.meta.find(c => c.class == this.courier.service_class);
      },
      deliveryEstimate() {
        const exclude = [
          'delivered',
          'picked_up_servicepoint',
          'delivered_servicepoint',
          'error',
          'incomplete',
          'blocked',
          'delivery_failure',
        ];
        // const exclude = [];
        if (
          (this.label.tracking_data?.estimated_delivery_start || this.label.tracking_data?.estimated_delivery_end) &&
          !exclude.includes(this.label.status)
        )
          return {
            start: this.label.tracking_data?.estimated_delivery_start,
            end: this.label.tracking_data?.estimated_delivery_end,
          };
        return null;
      },
      isAllowedToTicket() {
        return (
          process.env.VUE_APP_IS_UVDESK_ENABLED &&
          this.label.invoice_items?.find(item => item.type === 'label_price') !== undefined
        );
      },
      ticket() {
        const ticket = this.label.ticket;
        return ticket ? Object.keys(ticket).length > 0 : null;
      },
      history() {
        let history = this.label.tracking_data?.status_breadcrumbs?.slice() || [];
        this.label.tracking_mail_history?.forEach(element => {
          const description = this.translateMailStatuses(element.mail_status);
          if (description) {
            history.push({
              description: description,
              last_updated: element.timestamp,
            });
          }
        });
        if (this.ticket) {
          history.push({
            description: 'Ticket aangemaakt',
            last_updated: this.label.ticket.created_at,
          });
        }

        history.push({
          description: 'Laatste update',
          last_updated: this.label.updated_at,
          class: 'is-future',
        });
        history.push({
          description: 'Label gemaakt in Innosend',
          last_updated: this.label.created_at,
        });
        if (this.label.order_date)
          history.push({
            description: 'Bestelling geplaatst',
            last_updated: this.label.order_date,
          });
        if (this.deliveryEstimate)
          history.push({
            description: 'Bezorgmoment',
            last_updated: this.deliveryEstimate.end || this.deliveryEstimate.start,
            period: this.deliveryEstimate,
            class: 'is-future',
          });
        history.sort((a, b) => new Date(b.last_updated) - new Date(a.last_updated));
        return history;
      },
      courierFields() {
        let fields = [];
        if (this.courierMeta) {
          for (const fieldName in this.label.shipment.courier_fields) {
            const value = this.label.shipment.courier_fields[fieldName];
            if (value && value != 0) {
              fields.push({
                id: fieldName,
                props: this.courierMeta.order_fields.find(f => f.name == fieldName),
                value: value,
              });
            }
          }
        }
        return fields;
      },
    },
    methods: {
      async retry() {
        this.isLoading = true;
        await this.$store.dispatch('label/retryOrderUpdate', [this.label.id]);
        this.isLoading = false;
        this.loadLabel(true);
      },

      translateMailStatuses(status) {
        const mapping = {
          printed: 'Bestelling verwerkt mail verzonden',
          transit: 'Bestelling ontvangen mail verzonden',
          courier: 'Bezorger onderweg mail verzonden',
          delivered: 'Bestelling bezorgd mail verzonden',
          delivered_servicepoint: 'Bestelling afgeleverd servicepunt mail verzonden',
          return_from_delivery: 'Fout bij levering mail verzonden',
        };
        return mapping[status];
      },
      async printExportDoc(labelId) {
        this.printExportMutex = true;
        const doc = await this.$store.dispatch('label/getExportDoc', { labelId });
        download('export_doc.pdf', doc, 'application/pdf', 'base64');
        this.printExportMutex = false;
      },
      formatTimestampAsFunction(timestamp) {
        const format = 'YYYY-MM-DD HH:mm';
        return getMoment(timestamp).format(format);
      },
      getMappedStatusForSteps(status) {
        const mapping = {
          transit: 'Onderweg naar sorteercentrum',
          depot: 'In sorteercentrum',
          courier: 'Chauffeur is onderweg',
          delivered: 'Bezorgd',
          picked_up_servicepoint: 'Opgehaald servicepunt',
          delivered_servicepoint: 'Bezorgd servicepunt',
          delivery_failure: 'Aflevering mislukt',
          return_from_delivery: 'Aflevering retour',
          pending: 'Pakket is aangemeld',
        };
        if (Object.prototype.hasOwnProperty.call(mapping, status)) return mapping[status];
        return status;
      },
      async loadLabel(forceReload = false) {
        this.isLoading = true;
        try {
          this.label = await this.$store.dispatch('label/getLabel', { labelId: this.labelId, forceReload });
        } catch (error) {
          throw new ApiError();
        }
        this.isLoading = false;
      },
    },
    created() {
      this.loadLabel();
    },
    beforeRouteUpdate(to, from, next) {
      this.labelId = to.params.labelId;
      next();
    },
    filters: {
      formatValue(value) {
        if (value === true) return 'Ja';
        else if (value === false) return 'Nee';
        else if (!value) return '-';
        return value;
      },
      humanizeUnit(value, unit) {
        return Intl.NumberFormat('nl-NL', { style: 'unit', unit }).format(value);
      },
    },
  };
</script>
