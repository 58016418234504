var render = function render(){var _vm=this,_c=_vm._self._c;return _c('l-map',{attrs:{"center":_vm.averageLocation,"options":_vm.mapOptions,"zoom":_vm.zoom}},[_c('l-tile-layer',{attrs:{"url":_vm.url}}),_vm._l((_vm.pickup_points_by_courier),function({ locations, courier }){return _vm._l((locations),function(location){return _c('l-marker',{key:location.id,ref:`marker-${location.id}`,refInFor:true,attrs:{"lat-lng":{
        lng: location.geo.longitude,
        lat: location.geo.latitude,
      }},on:{"click":function($event){return _vm.setSelectedLocation(location)}}},[_c('l-icon',{attrs:{"class-name":_vm.cn('tw-group/marker', _vm.selectedLocation?.id === location.id && '!tw-pointer-events-none'),"icon-anchor":[24, 48],"icon-size":[48, 48],"popup-anchor":[0, -48]}},[_c('svg',{staticClass:"tw-fill-white group-hover/marker:tw-scale-125 tw-origin-bottom tw-transition-transform tw-w-12 tw-h-12 tw-stroke-black",class:{
            'tw-scale-125 tw-stroke-primary': _vm.selectedLocation?.id === location.id,
          },attrs:{"fill":"none","height":"100%","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24","width":"100%","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M20 10c0 6-8 12-8 12s-8-6-8-12a8 8 0 0 1 16 0Z"}}),_c('circle',{attrs:{"cx":"12","cy":"10","r":"3"}})])]),_c('l-popup',{attrs:{"options":{
          closeButton: true,
          closeOnClick: false,
          autoClose: false,
          closeOnEscapeKey: false,
        }}},[_c('div',{staticClass:"tw-flex tw-flex-col tw-gap-4"},[_c('address',[_c('h3',{staticClass:"tw-text-lg tw-font-semibold"},[_vm._v(_vm._s(location.name))])]),_c('OpeningHoursTable',{staticClass:"tw-min-w-64 tw-text-xs md:tw-hidden",attrs:{"location":location}})],1)])],1)})})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }