<template>
  <div class="tw-flex tw-gap-4 tw-flex-col">
    <address
      v-if="confirmed_location"
      class="tw-border-2 tw-w-fit tw-p-4 tw-border-solid tw-border-primary tw-rounded-2xl"
    >
      <b>{{ confirmed_location.name }}</b>
      <br />
      {{ confirmed_location.address.street_address }}
      <br />
      {{ confirmed_location.address.zip_code }} {{ confirmed_location.address.city }}
    </address>
    <div class="tw-flex tw-gap-4">
      <b-button native-type="button" @click="handleOpen">
        <span v-if="!confirmed_location">Afleverpunt specificeren</span>
        <span v-else>Wijzigen</span>
      </b-button>
      <b-button v-if="confirmed_location" native-type="button" type="is-danger" @click="reset"> Verwijderen</b-button>
    </div>
    <PickupModal v-if="open" @close="handleClose" />
  </div>
</template>

<script setup>
  import { ref, computed, provide } from 'vue';
  import PickupModal from '@/components/form/pickup/PickupModal.vue';

  const props = defineProps(['value', 'order']);
  const emit = defineEmits(['input']);

  const confirmed_location = ref(props.value);
  const selected_location = ref(props.value);
  const open = ref(false);

  function handleOpen() {
    open.value = true;
  }

  function handleClose() {
    open.value = false;
  }

  function reset() {
    confirmed_location.value = undefined;
    emit('input', undefined);
  }

  /** provide the order to children */
  provide(
    'order',
    computed(() => props.order),
  );

  provide(
    'confirmed_location',
    computed(() => confirmed_location.value),
  );
  provide('setConfirmedLocation', location => {
    confirmed_location.value = location;
    emit('input', location);
    handleClose();
  });

  provide(
    'selectedLocation',
    computed(() => selected_location.value),
  );

  provide('setSelectedLocation', location => {
    selected_location.value = location;
  });
</script>
