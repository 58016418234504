<template>
  <div :class="{ block: notifications.length > 0 }">
    <div v-if="notifications.length > 0" class="notifications container">
      <b-notification
        v-for="(notification, idx) in notifications"
        :key="idx"
        :closable="false"
        has-icon
        role="alert"
        type="is-warning"
      >
        <div class="content">
          <p>
            {{ notification.message }}
          </p>
          <p v-if="notification.cta">
            <b-button outlined tag="router-link" :to="notification.ctaTo" type="is-light">
              {{ notification.cta }}
            </b-button>
          </p>
        </div>
      </b-notification>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        notifications: [],
      };
    },
    methods: {
      initNotifications() {
        this.notifications = [];
        const organisation = this.$store.state.account.organisation;

        if (organisation?.status === 'incomplete')
          this.notifications.push({
            type: 'organisation_incomplete',
            message: `Je accountgegevens zijn niet volledig! Update de informatie om labels aan te kunnen maken.`,
            cta: 'Accountgegevens aanpassen',
            ctaTo: { name: 'first-login' },
          });

        if (
          organisation &&
          organisation.status === 'error' &&
          organisation.status_info.find(e => e.field === 'shipment_logic')
        )
          this.notifications.push({
            type: 'shipment_logic',
            message: `Er zitten fouten in je verzendregels. Pas deze aan om te zorgen dat orders correct worden ingeladen.`,
            cta: 'Verzendregels aanpassen',
            ctaTo: { name: 'shipmentlogic' },
          });

        if (!organisation.plans?.length)
          this.notifications.push({
            type: 'plan_missing',
            message: `Je hebt geen abonnement! Kies een abonnement om Innosend te gebruiken.`,
            cta: 'Abonnement kiezen',
            ctaTo: { name: 'plans' },
          });

        if (organisation?.can_create_labels === false)
          this.notifications.push({
            type: 'cannot_create_labels',
            message: `Je hebt helaas achterstallige betalingen in je account. Je kunt geen zendingen meer aanmaken totdat de facturen zijn betaald. Bel of mail ons gerust voor vragen op 050 211 09 22 of administratie@innosend.eu.`,
            cta: 'Facturen betalen',
            ctaTo: { name: 'billing' },
          });

        if (process.env.VUE_APP_SHOULD_CHECK_FOR_PAYMENT === 'true' && !organisation?.payment) {
          this.notifications.push({
            type: 'Automatische incasso',
            message: `Let op! Automatische incasso is nu verplicht! Activeer automatische incasso in je account instellingen, zodat je weer labels aan kan maken.`,
            cta: 'Naar Account',
            ctaTo: { name: 'account' },
          });
        } else if (process.env.VUE_APP_SHOULD_CHECK_FOR_PAYMENT === 'true' && organisation?.payment?.status !== 'ready') {
          this.notifications.push({
            type: 'Automatische incasso',
            message: `Let op! Er zit een fout in je automatische incasso! Probeer opnieuw een betaling te maken of neem contact op met Innosend.`,
            cta: 'Naar Account',
            ctaTo: { name: 'account' },
          });
        }

        if (organisation?.plan?.capabilities?.trial) {
          const to = organisation?.plan?.subscription?.to;
          if (to !== null) {
            const toDate = new Date(to).getTime();
            const today = new Date().getTime();
            const diffDays = Math.ceil((toDate - today) / (1000 * 60 * 60 * 24));
            if (diffDays <= 3 && diffDays > 0) {
              this.notifications.push({
                type: 'trial_expiry',
                message: `Let op! Trial periode verloopt over ${diffDays} dagen! Kies een abonnement om Innosend te blijven gebruiken.`,
                cta: 'Abonnement kiezen',
                ctaTo: { name: 'plans' },
              });
            } else if (diffDays === 0) {
              this.notifications.push({
                type: 'trial_expiry',
                message: `Let op! Dit is de laatste dag van je trial periode! Kies een abonnement om Innosend te blijven gebruiken.`,
                cta: 'Abonnement kiezen',
                ctaTo: { name: 'plans' },
              });
            }
          }
        }
      },
      initShopNotifications() {
        const shops = this.$store.state.shop.all.filter(o => o.service_class === 'Shopify');
        if (shops.length) {
          this.notifications.push({
            type: 'Shopify',
            message: `Actie vereist: We hebben de Shopify-app geupdated en daarom moet je de koppeling verversen om orders te verwerken.

Klik op je actieve Shopify koppeling en klik op repareren. Ververs de machtiging en je bent klaar.`,
            cta: 'Koppeling verversen',
            ctaTo: { name: 'services', params: { type: 'shop' } },
          });
        }
      },
    },
    created() {
      this.initNotifications();
      // this.initShopNotifications();
    },
    watch: {
      '$store.state.account.organisation'() {
        this.initNotifications();
      },
      '$store.state.shop.all'() {
        // this.initShopNotifications();
      },
    },
  };
</script>
