import { keycloak } from '@/utils/keycloak';

const state = {
  initialized: false,
  currentAuthHeaders: {}
};

const getters = {
  isAuthenticated: () => () => keycloak.authenticated // use function to get around reactive stuff
};

const mutations = {
  setInitialized(state, initialized) {
    state.initialized = initialized;
  },
  setAuthHeaders(state, token) {
    if (token) state.currentAuthHeaders = { Authorization: 'Bearer ' + token };
    else state.currentAuthHeaders = {};
  }
};

const actions = {
  async init(context) {
    await context.dispatch('initSession');
    // Token refresh
    setInterval(() => context.dispatch('refreshToken'), 6000);
    context.commit('setInitialized', true);
  },
  initSession(context) {
    context.commit('setAuthHeaders', keycloak.token);

    if (keycloak.authenticated) context.dispatch('afterLogin', null, { root: true });
  },
  async login(context) {
    if (!context.state.initialized) await context.dispatch('init');

    if (!context.getters.isAuthenticated()) {
      try {
        await keycloak.login();
        context.dispatch('initSession');
      } catch (e) {
        console.error(e);
      }
    }
  },
  async register(context) {
    if (!context.state.initialized) await context.dispatch('init');
    return keycloak.register();
  },
  async logout(context) {
    context.commit('setAuthHeaders', null);
    context.commit('account/setUser', null, { root: true });
    context.commit('account/setOrganisation', null, { root: true });
    const logoutOptions = { redirectUri: window.location.origin };
    await keycloak.logout(logoutOptions);
  },
  async refreshToken(context) {
    try {
      const refreshed = await keycloak.updateToken(70);
      if (refreshed) context.commit('setAuthHeaders', keycloak.token);
    } catch (e) {
      // Something went wrong, log for good measure. Might just be Firefox login loop…
      console.error('Error during token refresh, attempt to fix automatically:', e);
    }
  },
  async manageAccount() {
    await keycloak.accountManagement();
  },
};

export default {
  state,
  getters,
  mutations,
  actions
};
