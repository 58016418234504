<template>
  <article class="form-page">
    <div class="section">
      <header class="container block">
        <!-- I'm not too fond of this header, but it works for now... -->
        <nav class="block">
          <b-button icon-left="arrow-left" @click="$router.backOrDefault(defaultReturnRoute)"> Terug </b-button>
        </nav>
        <div class="level">
          <div class="level-left">
            <h1 v-if="title" class="title">{{ title }}</h1>
            <h1 v-else-if="action === 'update'" class="title">Bestelling aanpassen</h1>
            <h1 v-else class="title">Nieuwe zending</h1>
          </div>
        </div>
        <banner
          v-if="activeCouriers.length < 1"
          bannerText="Let op! Je hebt geen vervoerders gekoppeld. Koppel een vervoerder zodat de order volledig ingevuld kan worden, wat nodig is om een label te creëren"
          :buttonText="'Vervoerder(s) toevoegen'"
          :href="'/settings/services/courier'"
          :type="'is-warning'"
        />
      </header>
      <section class="container">
        <b-loading v-model="isLoading" :is-full-page="false"></b-loading>
        <status-info
          v-if="order.status_info"
          container-class="block"
          :info="order.status_info"
          item-class="notification is-danger"
        ></status-info>
        <form v-if="order" id="order-form" @submit.prevent="submit({ action: 'print', target: 'pdf', format: 'a6' })">
          <div class="columns">
            <div class="column is-half">
              <div class="field">
                <h2 class="title is-4">Ontvanger</h2>
              </div>
              <b-field label="Naam" :message="getErrorMessage('name')" :type="getErrorClass('name')">
                <b-input id="receiver-name" v-model="order.name" autocomplete="new-name" required></b-input>
              </b-field>
              <b-field
                label="Bedrijfsnaam"
                :message="getErrorMessage('company_name')"
                :type="getErrorClass('company_name')"
              >
                <b-input
                  id="receiver-company-name"
                  v-model="order.company_name"
                  autocomplete="new-organization"
                ></b-input>
              </b-field>
              <b-field label="Land" :message="getErrorMessage('country')" :type="getErrorClass('country')">
                <country-code-autocomplete v-model="order.country" required></country-code-autocomplete>
              </b-field>
              <b-field :message="getErrorMessage('state_province_code')" :type="getErrorClass('state_province_code')">
                <template #label>
                  Provincie/staat
                  <b-tooltip
                    v-if="!stateValid && order.state_province_code"
                    active
                    append-to-body
                    multilined
                    type="is-warning"
                  >
                    <font-awesome-icon class="is-size-5 has-text-warning" fixed-width icon="triangle-exclamation" />
                    <template #content
                      >Voor het geselecteerde land is een provincie/staat mogelijk niet verplicht.</template
                    >
                  </b-tooltip>
                </template>
                <state-province-code-auto-complete
                  v-model="order.state_province_code"
                  :country="order.country"
                  @state-valid="boolean => (stateValid = boolean)"
                ></state-province-code-auto-complete>
              </b-field>

              <b-field label="Postcode" :message="getErrorMessage('zipcode')" :type="getErrorClass('zipcode')">
                <b-input
                  id="receiver-zipcode"
                  v-model="order.zipcode"
                  autocomplete="new-postal-code"
                  required
                  @blur="triggerAddressCompletion"
                ></b-input>
              </b-field>
              <b-field
                label="Huisnummer en toevoeging"
                :message="getErrorMessage('housenumber')"
                :type="getErrorClass('housenumber')"
              >
                <b-input
                  id="receiver-housenumber"
                  v-model="order.housenumber"
                  autocomplete="new-housenumber"
                  @blur="triggerAddressCompletion"
                ></b-input>
              </b-field>
              <b-field label="Adres regel 1" :message="getErrorMessage('address_1')" :type="getErrorClass('address_1')">
                <b-input
                  id="receiver-address-1"
                  v-model="order.address_1"
                  autocomplete="new-address-line1"
                  required
                ></b-input>
              </b-field>
              <b-field label="Adres regel 2" :message="getErrorMessage('address_2')" :type="getErrorClass('address_2')">
                <b-input id="receiver-address-2" v-model="order.address_2" autocomplete="new-address-line2"></b-input>
              </b-field>
              <b-field label="Plaats" :message="getErrorMessage('city')" :type="getErrorClass('city')">
                <b-input id="receiver-city" v-model="order.city" autocomplete="new-address-level2" required></b-input>
              </b-field>
              <b-field label="E-mailadres" :message="getErrorMessage('email')" :type="getErrorClass('email')">
                <b-input
                  id="receiver-email"
                  v-model="order.email"
                  autocomplete="new-email"
                  required
                  type="email"
                ></b-input>
              </b-field>
              <b-field label="Telefoonnummer" :message="getErrorMessage('phone')" :type="getErrorClass('phone')">
                <b-input id="receiver-phone" v-model="order.phone" autocomplete="new-tel"></b-input>
              </b-field>
            </div>
            <div class="column is-half">
              <div class="field">
                <h2 class="title is-4">Order</h2>
              </div>
              <b-field
                label="Ordernummer"
                :message="getErrorMessage('order_number')"
                :type="getErrorClass('order_number')"
              >
                <b-input id="receiver-ordernumber" v-model="order.order_number"></b-input>
              </b-field>
              <b-field label="Valuta" :message="getErrorMessage('currency')" :type="getErrorClass('currency')">
                <b-select id="receiver-currency" v-model="order.currency" expanded placeholder="Selecteer valuta…">
                  <option v-for="currency in currencies" :key="currency" :value="currency">
                    {{ currency }}
                  </option>
                </b-select>
              </b-field>
              <b-field label="Orderdatum" :message="getErrorMessage('order_date')" :type="getErrorClass('order_date')">
                <b-datetimepicker
                  id="receiver-order-date"
                  ref="orderFormOrderDatepicker"
                  v-model="order.order_date"
                  editable
                  icon="calendar-today"
                  locale="nl-NL"
                  position="is-bottom-left"
                >
                  <template slot="right">
                    <b-button type="is-primary" @click="toggleOrderDatePicker">OK</b-button>
                  </template>
                </b-datetimepicker>
              </b-field>
              <div class="field">
                <h2 class="title is-4">Zending</h2>
              </div>
              <b-field
                label="Vervoerder"
                :message="getErrorMessage('shipment.courier_id')"
                :type="getErrorClass('shipment.courier_id')"
              >
                <b-select
                  id="receiver-courier"
                  v-model="order.shipment.courier_id"
                  expanded
                  placeholder="Selecteer vervoerder…"
                >
                  <option
                    v-for="courier in courierOptions"
                    :key="courier.id"
                    :disabled="!courier.is_active"
                    :value="courier.id"
                  >
                    {{ courier.service_name }}
                    <template v-if="!courier.is_active"> (verwijderd) </template>
                  </option>
                </b-select>
              </b-field>
              <b-field
                label="Verzendmethode"
                :message="getErrorMessage('shipment.shipment_type')"
                :type="getErrorClass('shipment.shipment_type')"
              >
                <b-select
                  id="receiver-shipment-type"
                  v-model="order.shipment.shipment_type"
                  expanded
                  placeholder="Selecteer verzendmethode…"
                >
                  <option v-for="type in shipmentTypeOptions" :key="type.id" :value="type.id">
                    {{ type.name || 'Standaard' }}
                  </option>
                </b-select>
              </b-field>

              <b-field label="Afleverpunt">
                <PickupField v-if="canSelectDropOffPoint" v-model="order.pickup_point" :order="order" />
                <template v-if="!canSelectDropOffPoint" #message>
                  Je moet op zijn minst een vervoerder selecteren en twee adresvelden zoals postcode en adres regel 1 of
                  plaats invullen om een afleverpunt te selecteren.
                </template>
              </b-field>

              <template v-if="selectedCourierMeta && selectedCourierMeta.order_fields.length > 0">
                <!-- Make sure fields are not reused between couriers -->
                <dynamic-field
                  v-for="field in selectedCourierMeta.order_fields"
                  :key="order.shipment.courier_id + '_' + field.name"
                  v-model="order.shipment.courier_fields[field.name]"
                  :disabled="shouldDisable(field)"
                  :field="field"
                  :message="getErrorMessage(`shipment.courier_fields.${field.name}`)"
                  :type="getErrorClass(`shipment.courier_fields.${field.name}`)"
                  @input="onChange($event, field)"
                >
                </dynamic-field>
              </template>

              <div class="field">
                <h2 class="title is-4">Pakket<template v-if="hasMoreThanOnePackage">ten</template></h2>
              </div>
              <div class="field">Gewicht</div>
              <div v-for="(_, idx) in order.shipment.packages" :key="`package-${idx}`" class="field">
                <b-field
                  :message="getErrorMessage(`shipment.packages.${idx}.weight`)"
                  :type="getErrorClass(`shipment.packages.${idx}.weight`)"
                >
                  <p v-if="hasMoreThanOnePackage" class="control">
                    <span class="button is-static"
                      >Pakket <template v-if="hasMoreThanOnePackage">{{ idx + 1 }}</template></span
                    >
                  </p>
                  <b-tooltip
                    v-if="order.shipment.packages[idx].weight > 23000"
                    active
                    class="control"
                    multilined
                    type="is-warning"
                  >
                    <p>
                      <span class="button is-static">
                        <font-awesome-icon class="has-text-warning" icon="exclamation-triangle" />
                      </span>
                    </p>
                    <template #content>
                      <p>Bij gewichten groter dan 23 KG worden er mogelijk extra kosten in rekening gebracht.</p>
                    </template>
                  </b-tooltip>
                  <b-input
                    :id="`receiver-shipment-weight-${idx}`"
                    v-model="order.shipment.packages[idx].weight"
                    expanded
                    min="0"
                    step="0.01"
                    type="number"
                  ></b-input>
                  <p class="control">
                    <span class="button is-static">gram</span>
                  </p>
                  <p v-if="hasMoreThanOnePackage" class="control">
                    <b-button
                      aria-label="Pakket verwijderen"
                      title="Pakket verwijderen"
                      type=""
                      @click="removePackage(idx)"
                    >
                      <font-awesome-icon icon="trash" />
                    </b-button>
                  </p>
                </b-field>
              </div>

              <b-button
                v-show="selectedCourierMeta && selectedCourierMeta.max_packages > 1"
                :id="`add-package-${order.shipment.packages.length}`"
                :disabled="!selectedCourierMeta || order.shipment.packages?.length >= selectedCourierMeta.max_packages"
                icon-left="math-plus"
                @click="addPackage"
              >
                Pakket toevoegen
              </b-button>
            </div>
          </div>
          <div class="field">
            <h2 class="title is-4">
              Orderregels
              <b-tooltip multilined type="is-dark">
                <span class="icon has-text-danger">
                  <i aria-hidden="true" class="gg-danger"></i>
                </span>
                <template v-slot:content>
                  <p>Extra kosten worden in rekening gebracht bij orders zwaarder dan 31kg</p>
                </template>
              </b-tooltip>
            </h2>
          </div>
          <div>
            <div class="table-container">
              <table
                v-if="order.order_items && order.order_items.length"
                class="table is-hoverable is-striped is-fullwidth"
              >
                <colgroup>
                  <!--checkbox-->
                  <col style="min-width: auto" />
                  <!--name-->
                  <col style="min-width: 175px" />
                  <!--description-->
                  <col style="min-width: 250px" />
                  <!--amount-->
                  <col style="min-width: 125px" />
                  <!--price per product-->
                  <col style="min-width: 125px" />
                  <!--total price-->
                  <col style="min-width: 125px" />
                  <!--weight-->
                  <col style="min-width: 125px" />
                  <!--total weight-->
                  <col style="min-width: 175px" />
                  <!--category-->
                  <col style="min-width: 150px" />
                  <!--sku-->
                  <col style="min-width: 150px" />
                  <!--hs code-->
                  <col style="min-width: 150px" />
                  <!--origin country-->
                  <col style="min-width: 150px" />
                  <!--actions-->
                  <col style="min-width: auto" />
                </colgroup>
                <thead>
                  <tr>
                    <th></th>
                    <th>Naam</th>
                    <th>Omschrijving</th>
                    <th>Aantal</th>
                    <th>Prijs per product ({{ order.currency }})</th>
                    <th>Totaal prijs ({{ order.currency }})</th>
                    <th>Gewicht per product (gram)</th>
                    <th>Totaal gewicht (gram)</th>
                    <th>Categorie</th>
                    <th>
                      SKU-code
                      <b-tooltip
                        append-to-body
                        multilined
                        style="position: relative; height: 100%; top: -25px; left: 90px"
                        type="is-dark"
                      >
                        <span class="icon has-text-info">
                          <i aria-hidden="true" class="gg-info"></i>
                        </span>
                        <template v-slot:content>
                          <p>Houd de SKU zo kort mogelijk</p>
                          <p>Vermijd het gebruik van 0 en 1</p>
                          <p>Vermijd het getal 0 aan het begin</p>
                        </template>
                      </b-tooltip>
                    </th>
                    <th>
                      <a
                        href="https://www.wcoomd.org/en/topics/nomenclature/instrument-and-tools/hs-nomenclature-2022-edition/hs-nomenclature-2022-edition.aspx"
                        target="_blank"
                        title="HS-code referentie"
                      >
                        HS-code
                      </a>
                    </th>
                    <th>Land van herkomst</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in order.order_items" :key="'order_item' + index">
                    <td class="is-vcentered">
                      <checkbox v-model="selectedOrderItems" :label-hidden="true" :native-value="index" />
                    </td>
                    <td class="is-vcentered">
                      <b-input
                        :id="`receiver-order-item-name-${index}`"
                        v-model="item.name"
                        autocomplete="first-name"
                      ></b-input>
                    </td>
                    <td class="is-vcentered">
                      <b-input
                        :id="`receiver-order-item-description-${index}`"
                        v-model="item.description"
                        autocomplete="false"
                      ></b-input>
                    </td>
                    <td class="is-vcentered">
                      <b-input
                        :id="`receiver-order-item-quantity-${index}`"
                        v-model="item.quantity"
                        autocomplete="false"
                        min="0"
                        type="number"
                      ></b-input>
                    </td>
                    <td class="is-vcentered">
                      <b-input
                        :id="`receiver-order-item-unit-price-${index}`"
                        v-model="item.unit_price_inc_btw"
                        autocomplete="false"
                        min="0"
                        step="0.01"
                        type="number"
                      ></b-input>
                    </td>
                    <td class="is-vcentered">
                      <b-field>
                        <b-input disabled :value="(item.unit_price_inc_btw * item.quantity).toFixed(2)" />
                      </b-field>
                    </td>
                    <td class="is-vcentered">
                      <b-input
                        :id="`receiver-order-item-weight-${index}`"
                        v-model="item.weight"
                        autocomplete="false"
                        min="0"
                        step="0.01"
                        type="number"
                      ></b-input>
                    </td>
                    <td>
                      <b-field>
                        <b-input disabled :value="(item.weight * item.quantity).toFixed(0)" />
                        <p class="control">
                          <b-button @click="copyTextToClipboard((item.weight * item.quantity).toFixed(0))">
                            <font-awesome-icon icon="copy" />
                          </b-button>
                        </p>
                      </b-field>
                    </td>
                    <td class="is-vcentered">
                      <b-input
                        :id="`receiver-order-item-category-${index}`"
                        v-model="item.category"
                        autocomplete="false"
                      ></b-input>
                    </td>
                    <td class="is-vcentered">
                      <b-input
                        :id="`receiver-order-item-sku-${index}`"
                        v-model="item.sku"
                        autocomplete="false"
                      ></b-input>
                    </td>
                    <td class="is-vcentered">
                      <b-input
                        :id="`receiver-order-item-hs-code-${index}`"
                        v-model="item.hs_code"
                        autocomplete="false"
                        :has-counter="false"
                        maxlength="10"
                      ></b-input>
                    </td>
                    <td class="is-vcentered">
                      <country-code-autocomplete
                        :id="`receiver-order-item-origin-country-${index}`"
                        v-model="item.product_country"
                      ></country-code-autocomplete>
                    </td>
                    <td class="is-vcentered">
                      <b-button icon-left="trash" type="is-danger" @click="deleteOrderItem(index)" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="buttons">
              <b-button id="add-order-item-button" icon-left="math-plus" @click="addOrderItem">
                Voeg regel toe
              </b-button>
              <b-button
                :disabled="selectedOrderItems.length <= 0"
                icon-left="trash"
                type="is-danger"
                @click="deleteSelectedOrderItems"
              >
                Verwijder regels
              </b-button>
            </div>
          </div>
        </form>
      </section>
    </div>
    <form-page-footer>
      <div class="container field is-grouped is-grouped-right">
        <p class="control">
          <b-button @click="$router.backOrDefault(defaultReturnRoute)"> Annuleren </b-button>
        </p>
        <b-field class="has-dropdown">
          <p class="control">
            <template v-if="printerClientIsReady">
              <b-button
                :disabled="isSubmitting"
                :loading="isSubmitting"
                type="is-primary"
                @click="submit({ action: 'print', target: 'printer', format: 'a6' })"
              >
                {{ baseConfirmText }} en afdrukken
              </b-button>
            </template>
            <template v-else>
              <b-button
                :disabled="isSubmitting"
                :loading="isSubmitting"
                type="is-primary"
                @click="submit({ action: 'print', target: 'pdf', format: 'a6' })"
              >
                {{ baseConfirmText }} en PDF downloaden
              </b-button>
            </template>
          </p>
          <div class="control">
            <b-dropdown position="is-top-left">
              <template #trigger>
                <b-button
                  aria-label="Meer opties"
                  :disabled="isSubmitting"
                  icon-right="chevron-up"
                  :loading="isSubmitting"
                ></b-button>
              </template>
              <b-dropdown-item aria-role="listitem" :disabled="isSubmitting" :loading="isSubmitting" @click="submit">
                <template v-if="confirmText">{{ confirmText }}</template>
                <template v-else-if="action === 'update'">Bestelling aanpassen</template>
                <template v-else>Zending aanmaken</template>
              </b-dropdown-item>
              <b-dropdown-item
                aria-role="listitem"
                :disabled="isSubmitting"
                :loading="isSubmitting"
                @click="submit({ action: 'modal', target: 'pdf', format: 'a4' })"
              >
                {{ baseConfirmText }}
                en afdrukopties tonen
              </b-dropdown-item>
              <b-dropdown-item
                aria-role="listitem"
                :disabled="isSubmitting || !canPrintExportDoc"
                :loading="isSubmitting"
                @click="submit({ action: 'export' })"
              >
                {{ baseConfirmText }}
                en alleen export document downloaden
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </b-field>
      </div>
    </form-page-footer>
  </article>
</template>

<script>
  import Checkbox from '@/components/form/Checkbox';
  import { mapGetters, mapState } from 'vuex';
  import { download, getMoment, printLabel, reportLabelError, reportLabelWarning } from '@/utils/functions';
  import CountryCodeAutocomplete from '@/components/form/CountryCodeAutocomplete';
  import StateProvinceCodeAutoComplete from '@/components/form/StateProvinceCodeAutoComplete';
  import DynamicField from '@/components/form/DynamicField';
  import FormPageFooter from '@/components/form/FormPageFooter';
  import PrintModal from '@/components/modals/PrintModal.vue';
  import StatusInfo from '@/components/properties/StatusInfo';
  import Banner from '@/components/Banner.vue';
  import { ApiError } from '@/utils/errors';
  import PickupField from '@/components/form/pickup/PickupField.vue';

  export default {
    components: {
      PickupField,
      CountryCodeAutocomplete,
      DynamicField,
      FormPageFooter,
      StatusInfo,
      Checkbox,
      Banner,
      StateProvinceCodeAutoComplete,
    },
    data() {
      const { errors, order } = this.initOrder();

      return {
        stateValid: true,
        defaultReturnRoute: { name: 'dashboard', params: { activeTabName: 'orders' } },
        isLoading: false,
        has_delivery_point: false,
        isSubmitting: false,
        selectedOrderItems: [],
        currencies: ['EUR', 'USD', 'AUD', 'GBP', 'CAD', 'DKK', 'JPY', 'NZD', 'NOK', 'TRL', 'ZAR', 'SEK', 'CHF'],
        order,
        errors,
        orderId: this.$route.params.orderId,
        confirmText: this.$route.params.confirmText,
        title: this.$route.params.title,
        insurance: '0',
      };
    },
    computed: {
      /** users can select a drop-off point if they have filled in at least 2 address fields */
      canSelectDropOffPoint() {
        const addressFields = [this.order.address_1, this.order.city, this.order.zipcode];

        // Count the number of filled address fields
        const filledAddressFields = addressFields.filter(field => !!field).length;

        // Check if courier_id is filled and at least 2 address fields are filled
        return filledAddressFields >= 2;
      },

      ...mapState({
        couriers: state => state.courier.all,
        activeCouriers(state) {
          return state['courier'].all.filter(o => o.is_active).sort((a, b) => a.id.localeCompare(b.id));
        },
      }),
      ...mapGetters({
        printerClientIsReady: 'app/printerClientIsReady',
      }),
      action() {
        if (this.orderId) return 'update';

        return 'create';
      },
      baseConfirmText() {
        if (this.confirmText) return this.confirmText;
        else if (this.action === 'update') return 'Aanpassen';
        return 'Aanmaken';
      },
      total_weight() {
        return this.order.order_items?.reduce((sum, item) => {
          return sum + item.weight * item.quantity;
        }, 0);
      },
      hasMoreThanOnePackage() {
        return this.order.shipment.packages.length > 1;
      },
      isExport() {
        return !!(this.orderId && this.order.validators && this.order.validators.is_export);
      },
      courierOptions() {
        return this.couriers.filter(c => c.is_active || c.id === this.order.shipment.courier_id);
      },
      shipmentTypeOptions() {
        if (this.selectedCourier) return this.selectedCourier.shipment_types;
        return [];
      },
      selectedCourier() {
        return this.couriers.find(c => c.id === this.order.shipment.courier_id);
      },
      isDPDSelected() {
        // cannot use service_class because of proxycouriers
        const allowedCouriers = ['DPD Innosend', 'Innosend DPD Extra', 'DPD Home', 'DPD Classic'];
        return !!(this.selectedCourier && allowedCouriers.includes(this.selectedCourier.service_name));
      },
      selectedCourierMeta() {
        return this.$store.getters['courier/metaById'](this.order.shipment.courier_id);
      },
      canPrintExportDoc() {
        // TODO env var?
        // op dit moment geen env var omdat het bij de functie hoort en toch bijna nooit veranderd
        const eu_countries = [
          'AT',
          'BE',
          'BG',
          'CY',
          'CZ',
          'DE',
          'DK',
          'EE',
          'ES',
          'FI',
          'FR',
          'GR',
          'HR',
          'HU',
          'IC',
          'IE',
          'IT',
          'LT',
          'LU',
          'LV',
          'MT',
          'NL',
          'PL',
          'PT',
          'RO',
          'SE',
          'SI',
          'SK',
        ];
        if (eu_countries.includes(this.order.country)) {
          return false;
        }
        if (this.order?.status_info?.length > 0) {
          return false;
        }

        if (this.order?.validators?.is_export && this.order.order_items?.length === 0) {
          return false;
        }

        if (this.order.order_items?.length > 0) {
          let checkArray = this.order.order_items.filter(item => {
            return (
              item.description &&
              item.quantity &&
              item.quantity &&
              item.unit_price_inc_btw &&
              item.weight &&
              item.category &&
              item.sku &&
              item.hs_code &&
              item.product_country &&
              item.name
            );
          });
          // if array is empty, then it means that a value is missing.
          return checkArray.length === this.order.order_items.length;
        }
        return false;
      },
    },
    created() {
      // I don't really like it, but it works - Martijn
      this.isLoading = true;
      const withCourier = async () => {
        if (this.orderId) {
          try {
            const order = await this.$store.dispatch('order/getOrder', this.orderId);
            Object.assign(this.$data, this.initOrder(order));
          } catch (error) {
            throw new ApiError();
          }
        }
        this.initCourier();
        this.isLoading = false;
      };
      if (!this.$store.state.courier.initialized) {
        const unwatch = this.$store.watch(
          () => this.$store.state.courier.initialized,
          () => {
            if (this.$store.state.courier.initialized) {
              unwatch();
              withCourier();
            }
          },
        );
      } else {
        withCourier();
      }
    },
    methods: {
      copyTextToClipboard(value) {
        navigator.clipboard.writeText(value);
      },
      // TODO: this logic should be in courier fields definition
      isMyParcelSelected() {
        return this.selectedCourierMeta.name === 'MyParcel' || this.selectedCourierMeta.name === 'PostNL Innosend';
      },
      onChange(value, field) {
        if (this.isMyParcelSelected() && field.name === 'insurance') {
          // used for shouldDisable
          this.insurance = value;
        }
      },
      shouldDisable(field) {
        if (this.isMyParcelSelected()) {
          if (
            this.insurance !== '0' ||
            (this.order.shipment.courier_fields.insurance !== '0' && this.order.shipment.courier_fields.insurance)
          ) {
            if (field.name === 'only_recipient' || field.name === 'signature') {
              return true;
            }
          }
        }

        return false;
      },
      // end TODO
      addOrderItem() {
        if (!this.order.order_items) {
          this.order.order_items = [];
        }
        this.order.order_items.push({
          category: null,
          description: null,
          hs_code: null,
          id: null,
          name: null,
          product_country: null,
          quantity: null,
          sku: null,
          total_price: null,
          total_weight: null,
          unit_price_inc_btw: null,
          weight: null,
        });
      },
      addPackage() {
        if (this.order.shipment.packages.length >= this.selectedCourierMeta.max_packages) return;
        this.order.shipment.packages.push({
          weight: null,
        });
      },
      removePackage(idx) {
        this.order.shipment.packages.splice(idx, 1);
      },

      deleteOrderItem(index) {
        this.order.order_items.splice(index, 1);
      },
      deleteSelectedOrderItems() {
        this.order.order_items = this.order.order_items.filter((_, index) => !this.selectedOrderItems.includes(index));
        this.selectedOrderItems = [];
      },
      initCourier() {
        if (this.order.shipment.courier_id) {
          // Avoid confusion about proxy services
          const courier = this.$store.getters['courier/courierById'](this.order.shipment.courier_id);
          this.order.shipment.courier_id = courier.id;

          if (!this.order.shipment.courier_fields) this.order.shipment.courier_fields = {};

          // Make sure we've initialized all courier fields
          const meta = this.$store.getters['courier/metaById'](this.order.shipment.courier_id);
          if (meta.order_fields.length > 0) {
            for (let field of meta.order_fields) {
              if (!this.order.shipment.courier_fields[field.name])
                this.order.shipment.courier_fields[field.name] = null;

              this.setError(`shipment.courier_fields.${field.name}`, []);
            }
          }
        }

        // Try our best to set a default option
        if (!this.order.shipment.courier_id && !this.order.shipment.shipment_type && this.courierOptions.length > 0) {
          this.order.shipment.courier_id = this.courierOptions[0].id;

          if (this.order.shipment.courier_id) {
            const courier = this.$store.getters['courier/courierById'](this.order.shipment.courier_id);
            const type = courier.shipment_types.find(t => t.is_default);
            this.order.shipment.shipment_type = type ? type.id : null;
          }
        }

        this.verifyService();
        this.initCourierFields();
      },
      initCourierFields() {
        if (this.selectedCourierMeta && this.selectedCourierMeta.order_fields.length > 0) {
          if (!this.order.shipment.courier_fields) this.order.shipment.courier_fields = {};

          for (let field of this.selectedCourierMeta.order_fields) {
            const fieldName = this.order.shipment.courier_fields[field.name];
            this.order.shipment.courier_fields[field.name] = fieldName !== null ? fieldName : null;
            this.setError(`shipment.courier_fields.${field.name}`, []);
          }
        }
      },
      initOrder(orderData) {
        let order = {
          name: null,
          company_name: null,
          country: 'NL',
          state_province_code: '',
          zipcode: null,
          housenumber: null,
          address_1: null,
          address_2: null,
          city: null,
          email: null,
          phone: null,
          currency: 'EUR',
          order_number: null,
          order_date: new Date(),
          order_items: [],
          order_status: null,
          pickup_point: null,
          shipment: {
            courier_id: null,
            shipment_type: null,
            courier_fields: {},
            packages: [
              {
                weight: null,
              },
            ],
          },
        };

        let errors = {};

        if (!orderData && this.$route.params.prefilledData) orderData = this.$route.params.prefilledData;

        if (orderData) {
          Object.assign(order, structuredClone(orderData));
          if (orderData.courier_fields) order.shipment.courier_fields = { ...orderData.courier_fields };
          if (orderData.order_items) order.order_items = [...orderData.order_items];
        }

        if (!(order.order_date instanceof Date)) order.order_date = getMoment(order.order_date).toDate();

        if (order.status_info) {
          for (let item of order.status_info) {
            if (item.field && errors[item.field]) {
              if (item.type === 'missing')
                errors[item.field].push({ text: 'Geen waarde ingevuld.', type: item.type, status: item.status });
              if (item.type === 'missing_shipment_logic_rule_value')
                errors[item.field].push({
                  text: 'Verzendregel kan niet worden uitevoerd door het ontbreken van waarde',
                  type: item.type,
                  status: item.status,
                });
              if (item.type === 'no_validation')
                errors[item.field].push({
                  text: 'Innosend is niet in staat om dit veld te valideren. Dit doet de webshop of vervoerder.',
                  type: item.type,
                  status: item.status,
                });
              if (item.type === 'inactive')
                errors[item.field].push({ text: 'Optie niet beschikbaar.', type: item.type, status: item.status });
              if (item.type === 'form_validation' && item.data !== undefined) {
                for (const field in item.data) {
                  if (!errors[item.field][field]) errors[item.field][field] = [];
                  errors[item.field][field].push({ text: item.data[field], type: item.type, status: item.status });
                }
              }
            }
          }
        }

        return { order, errors };
      },
      getError(field) {
        const fn = (err, path) => {
          const name = path.shift();
          // Make sure we return a reference to a part of this.errors, even if it's empty.
          // This allows modifying the response of getErrors, which is ugly, but needed.
          if (!err[name] && path.length === 0) err[name] = [];
          else if (!err[name]) err[name] = {};

          if (path.length === 0) return err[name];
          return fn(err[name], path);
        };
        return fn(this.errors, field.split('.'));
      },
      setError(field, value) {
        if (!Array.isArray(value)) value = [value];
        const fn = (err, path) => {
          const name = path.shift();
          if (!err[name] && path.length === 0) err[name] = [];
          else if (!err[name]) err[name] = {};

          if (path.length === 0) err[name] = value;
          else return fn(err[name], path);
        };
        return fn(this.errors, field.split('.'));
      },
      getErrorClass(field) {
        let status_type = 'default';
        const error = this.getError(field);
        const prio = {
          error: 10,
          warning: 5,
          check_required: 5,
          info: 1,
          default: 0,
        };
        const mapping = {
          error: 'is-danger',
          warning: 'is-warning',
          check_required: 'is-warning',
          info: 'is-info',
        };

        error?.forEach(obj => {
          if (status_type === 'error') {
            return 'is-danger';
          }
          // instances can be string instead of object, defined in initOrder
          // TODO can this statement be avoided, by making every error an object?
          if (typeof obj === 'string') {
            return 'is-danger';
          }
          if (prio[obj.status] > prio[status_type]) status_type = obj.status;
        });
        return mapping[status_type];
      },
      getErrorMessage(field) {
        const error = this.getError(field);
        let message = '';

        error?.forEach(obj => {
          message += obj.text ? obj.text : obj;
        });
        return message;
      },
      async submit(options) {
        this.isSubmitting = true;

        let action = 'order/createOrder';
        if (this.action === 'update') action = 'order/updateOrder';
        try {
          await this._submit(action, options);
        } catch (e) {
          const response = e.response;
          if (response && response.type === 'validation' && response.message_code === 'form_invalid') {
            // TODO: Find neater solution. This is horrible.
            // We could just do this, if it wasn't for that stupid {text, type, status} object that doesn't really work
            // Object.assign(this.errors, response.fields);
            for (const field in response.fields) {
              if (field === 'shipment') {
                for (const f in response.fields.shipment) {
                  if (f === 'courier_fields') {
                    for (const f in response.fields.shipment.courier_fields) {
                      const messageArray = response.fields.shipment.courier_fields[f];
                      this.getError(`shipment.courier_fields.${f}`).push(...messageArray);
                    }
                  } else if (f === 'packages') {
                    for (let idx = 0; idx < response.fields.shipment.packages.length; idx++) {
                      for (const pf in response.fields.shipment.packages[idx]) {
                        const messageArray = response.fields.shipment.packages[idx][pf];
                        this.getError(`shipment.packages.${idx}.${pf}`).push(...messageArray);
                      }
                    }
                  } else {
                    const messageArray = response.fields.shipment[f];
                    this.getError(`shipment.${f}`).push(...messageArray);
                  }
                }
              } else {
                response.fields[field].forEach(message => {
                  this.getError(field).push({ text: message + ' ', type: response.type, status: response.status });
                });
              }
            }
          } else {
            throw e;
          }
        } finally {
          this.isSubmitting = false;
        }
      },
      async _submit(storeAction, options) {
        const order = await this.$store.dispatch(storeAction, this.order);
        if (options?.action) {
          let fn = () => this.afterSubmit();
          if (options?.action === 'print')
            fn = async ({ label }) => {
              await this.printLabel(label, options);
              this.afterSubmit();
            };
          else if (options?.action === 'export')
            fn = async ({ label }) => {
              await this.printExportDoc(label);
              this.afterSubmit();
            };
          else if (options?.action === 'modal')
            fn = async ({ label }) =>
              this.$buefy.modal.open({
                parent: this,
                component: PrintModal,
                hasModalCard: true,
                trapFocus: true,
                props: {
                  parameters: {
                    labelIds: [label.id],
                    ...options,
                  },
                  title: 'Label PDF downloaden',
                  onConfirm: async () => {
                    await this.printLabel(label, options);
                    this.afterSubmit();
                  },
                },
              });
          const label = await this.createLabel(order.id, fn);
          if (label)
            this.$router.push({
              name: 'label',
              params: {
                labelId: label?.id,
              },
            });
          else this.$router.push(this.defaultReturnRoute);
        } else {
          this.afterSubmit();
          this.$router.push(this.defaultReturnRoute);
        }
      },
      afterSubmit() {
        this.isSubmitting = false;
      },
      async printLabel(label, options) {
        await printLabel({ labelIds: [label.id], ...options });
        if (this.canPrintExportDoc) await this.printExportDoc(label);
      },
      async printExportDoc(label) {
        const doc = await this.$store.dispatch('label/getExportDoc', { labelId: label.id });
        download('export_doc.pdf', doc, 'application/pdf', 'base64');
      },
      toggleOrderDatePicker() {
        this.$refs.orderFormOrderDatepicker.toggle();
      },
      triggerAddressCompletion() {
        function isEmpty(s) {
          return !((typeof s === 'string' || s instanceof String) && s !== '');
        }
        if (!isEmpty(this.order.zipcode) && !isEmpty(this.order.housenumber)) {
          let zipcode = this.order.zipcode.replace(' ', '');
          let housenumber = this.order.housenumber.replace(' ', '');
          let q = 'postcode:"' + zipcode + '" and huis_nlt:"' + housenumber + '" and type:adres';
          let fl = 'fl=straatnaam,woonplaatsnaam,type';
          let url = 'https://api.pdok.nl/bzk/locatieserver/search/v3_1/free?rows=1&' + fl + '&q=' + encodeURI(q);
          fetch(url)
            .then(resp => {
              if (resp.ok) {
                resp.json().then(data => {
                  data = data.response;
                  if (
                    data.numFound != 1 || // No address found
                    data.docs[0].type !== 'adres'
                  )
                    // Returned object is not an address
                    return;
                  this.order.address_1 = data.docs[0].straatnaam;
                  this.order.city = data.docs[0].woonplaatsnaam;
                });
              }
            })
            .catch(error => {
              console.log(
                'Error fetching address data: ' + error,
                'Most likely the API is down, check if the following link works: https://api.pdok.nl',
              );

              this.$buefy.toast.open({
                duration: 5000,
                message:
                  'We konden de address gegevens niet automatisch aanvullen.<br>De service die we hiervoor gebruiken is mogelijk niet beschikbaar. Probeer het later nog eens.',
                position: 'is-top',
                type: 'is-warning',
              });
            });
        }
      },
      verifyService(id = null) {
        if (!id) id = this.order.shipment.courier_id;

        const message = 'Kies een vervoerder die niet is verwijderd';
        const errors = this.getError('shipment.courier_id');
        const idx = errors.indexOf(message);
        if (idx >= 0) errors.splice(idx, 1);

        const courier = this.$store.getters['courier/courierById'](id);
        if (courier && !courier.is_active) errors.push(message);
      },
      async createLabel(orderId, callback, force = false) {
        try {
          const result = await this.$store.dispatch('label/createLabels', { orderIds: [orderId], force });

          if (result.errors.length > 0) reportLabelError(result);

          const label = result.data.find(l => l.order_id == orderId);
          callback({ label });

          return label;
        } catch (error) {
          if (error instanceof ApiError && error.response.status === 'warning') {
            reportLabelWarning(error.response, {
              cancelText: 'Annuleren',
              confirmText: 'Labels aanmaken',
              onConfirm: () => this.createLabel(orderId, callback, true),
            });
          } else if (error instanceof ApiError) {
            reportLabelError(error.response);
          } else {
            throw error;
          }
        }
      },
    },
    watch: {
      'order.order_items': {
        handler() {
          if (this.order.shipment.packages.length === 1) {
            this.order.shipment.packages[0].weight = this.total_weight;
          }
        },
        deep: true,
      },
      'order.shipment.courier_id'(newValue, oldValue) {
        if (newValue !== oldValue) {
          if (
            this.order.shipment.shipment_type &&
            this.selectedCourier.shipment_types.find(t => t.id === this.order.shipment.shipment_type)
          ) {
            // do nothing
          } else if (this.selectedCourier) {
            const type = this.selectedCourier.shipment_types.find(t => t.is_default);
            this.order.shipment.shipment_type = type ? type.id : null;
          } else {
            this.order.shipment.shipment_type = null;
          }

          // Reset sourier fields on change, but only if oldValue wasn't empty
          if (oldValue) this.order.shipment.courier_fields = {};
        }
        this.verifyService(newValue);
        this.initCourierFields();
      },
      'isDPDSelected'(val) {
        if (val !== true) {
          this.order.shipment_packages = 1;
        }
      },
    },
    beforeRouteUpdate(to, from, next) {
      next();
    },
  };
</script>
