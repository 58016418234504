<template>
  <section>
    <div class="content">
      <slot></slot>
    </div>
    <b-collapse v-if="hasDetails" aria-id="errorDetails" :open="false">
      <template #trigger="props">
        <a aria-controls="errorDetails">
          <b-icon :icon="!props.open ? 'chevron-down' : 'chevron-up'"></b-icon>
          {{ !props.open ? 'Details weergeven' : 'Details verbergen' }}
        </a>
      </template>
      <div class="content">
        <slot name="details"></slot>
      </div>
    </b-collapse>
  </section>
</template>

<script>
  export default {
    computed: {
      hasDetails() {
        return !!this.$slots.details;
      }
    }
  };
</script>
