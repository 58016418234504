import * as Sentry from '@sentry/vue';
import { ApiError } from './errors';

const API_BASE_RUL = process.env.VUE_APP_PRINTER_CLIENT_URL;
const SERVER_URL = process.env.VUE_APP_PRINTER_SERVER_URL;

async function fetchData(url, method, body) {
  const headers = new Headers({
    'Content-Type': 'application/json'
  });

  let init = {
    method: method,
    headers: headers
  };

  if (body) init['body'] = JSON.stringify(body);

  let response;
  try {
    response = await fetch(`${API_BASE_RUL}${url}`, init);
  } catch (error) {
    if (error instanceof TypeError) throw new ApiError('Unable to connect to printer client API', null);
    else throw error;
  }

  if (!response.ok) throw new ApiError('Printer client API returned error on ' + url, await response.json());

  return await response.json();
}

export default {
  async connect() {
    const currentServerUrl = (await fetchData('serverurl', 'GET')).serverurl;
    const connected = (await fetchData('connected', 'GET')).connected;

    if (currentServerUrl === SERVER_URL && connected === 'True') return;

    Sentry.captureMessage('Printerclient reconnect needed', {
      contexts: {
        printerclient: { currentServerUrl, connected }
      }
    });

    // Disconnect if needed
    if (connected === 'True') await fetchData('disconnect', 'POST');

    // Set our server url
    await fetchData('serverurl', 'POST', { serverurl: SERVER_URL });

    // (Re)connect
    await fetchData('connect', 'POST');
  },
  async getClientId() {
    const result = await fetchData('printerclientid', 'GET');
    return result.printerclientid;
  },
  getPrinters() {
    return fetchData('printers', 'GET');
  },
  setPrinter(printer) {
    return fetchData('setlabelprinter', 'POST', printer);
  }
};
