<template>
  <span class="courier">
    <b-tooltip position="is-right" type="is-dark">
      <template v-if="shop">
        <figure v-if="meta && meta.images.mark" class="image">
          <img :alt="shop.service_name" :src="meta.images.mark" />
        </figure>
      </template>
      <template v-else>
        <figure class="image">
          <img alt="Innosend" src="../../assets/favicon-32x32.png" />
        </figure>
      </template>
      <template v-slot:content>
        <p v-if="shop">{{ shop.service_name }}</p>
        <p v-else>Handmatig aangemaakt of geïmporteerd uit CSV</p>
      </template>
    </b-tooltip>
  </span>
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    props: {
      item: Object
    },
    computed: {
      ...mapState({
        shops: state => state['shop'].meta
      }),
      shop() {
        return this.$store.getters['shop/shopById'](this.item.service_id);
      },
      meta() {
        return this.$store.state.shop.meta.find(c => c.class == this.shop?.service_class);
      }
    }
  };
</script>
