<template>
  <b-field :label="isInputType(field) ? field.label : null" :message="message" :type="type">
    <checkbox
      v-if="field.type === 'checkbox'"
      v-model="model"
      :disabled="disabled"
      :name="field.name"
      :required="field.required"
      @input="$emit('input', model)"
      >{{ field.label }}
    </checkbox>
    <b-datetimepicker
      v-else-if="field.type === 'datetime'"
      ref="dynamicFieldDateTimePicker"
      v-model="model"
      editable
      icon="calendar-today"
      locale="nl-NL"
      position="is-bottom-left"
      @input="$emit('input', model)"
    >
      <template slot="right">
        <b-button type="is-primary" @click="togglePicker('dynamicFieldDateTimePicker')">OK</b-button>
      </template>
    </b-datetimepicker>
    <b-select
      v-else-if="field.type === 'select'"
      :id="field.name"
      v-model="model"
      expanded
      :required="field.required"
      @input="$emit('input', model)"
    >
      <option v-for="option in field.options" :key="option.value" :value="option.value">
        {{ option.label }}
      </option>
    </b-select>
    <b-input
      v-else
      v-model="model"
      :required="field.required"
      :type="field.type"
      @input="$emit('input', model)"
    ></b-input>
  </b-field>
</template>

<script>
  import Checkbox from './Checkbox';
  import { getMoment } from '@/utils/functions';

  export default {
    components: { Checkbox },
    props: {
      field: Object,
      message: String,
      type: String,
      value: null,
      disabled: Boolean
    },
    data() {
      let model = this.value;

      if (this.field.type === 'datetime' && model && !(model instanceof Date)) model = getMoment(model).toDate();

      return {
        model
      };
    },
    computed: {
      console: () => console
    },
    methods: {
      isInputType(field) {
        return field.type !== 'checkbox';
      },
      togglePicker(ref) {
        this.$refs[ref].toggle();
      },
      setDefault() {
        if (!this.model && this.field.default) {
          let value = this.field.default;

          if (this.field.type === 'datetime' && value && !(value instanceof Date)) value = getMoment(value).toDate();

          this.model = value;

          this.$emit('input', this.model);
        }
      }
    },
    created() {
      this.setDefault();
    }
  };
</script>
