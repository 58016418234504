<template>
  <span class="country">
    <template v-if="format === 'long'">
      <figure v-if="flag" class="icon" v-html="flagSvg"></figure>
      {{ countryName }}
    </template>
    <b-tooltip v-else-if="format === 'short'" :label="countryName" position="is-right" type="is-dark">
      <figure v-if="flag" class="icon" v-html="flagSvg"></figure>
      {{ value?.toUpperCase() }}
    </b-tooltip>
  </span>
</template>

<script>
  import { hasFlag } from 'country-flag-icons';
  import * as flags from 'country-flag-icons/string/3x2';
  import * as countries from '@/utils/countries';

  export default {
    props: {
      value: String,
      format: {
        type: String,
        default: 'long'
      },
      flag: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      flagSvg() {
        if (hasFlag(this.value)) return flags[this.value];
        return null;
      },
      countryName() {
        return countries.getName(this.value, 'nl') || '';
      },
    },
  };
</script>
