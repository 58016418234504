import TransferModal from '@/components/modals/TransferModal.vue';
import { download } from '@/utils/functions';

export default {
  methods: {
    async newOrderFromOrder(order) {
      const { shouldTransferOrderNumber, shouldTransferShipmentSettings } = await new Promise(resolve => {
        this.$buefy.modal.open({
          component: TransferModal,
          parent: this,
          hasModalCard: true,
          props: {
            onConfirm: async toTranfer => {
              resolve(toTranfer);
            },
          },
        });
      });

      const newOrder = {
        address_1: order.address_1,
        address_2: order.address_2,
        city: order.city,
        company_name: order.company_name,
        country: order.country,
        courier_id: order.courier_id,
        currency: order.currency,
        email: order.email,
        housenumber: order.housenumber,
        name: order.name,
        order_items: order.order_items,
        order_number: shouldTransferOrderNumber ? order.order_number : null,
        phone: order.phone,
        shipment: {
          ...order.shipment,
          courier_fields: shouldTransferShipmentSettings ? order.shipment.courier_fields : null,
        },
        state_province_code: order.state_province_code,
        zipcode: order.zipcode,
      };
      this.$router.push({
        name: 'order_create',
        params: {
          prefilledData: newOrder,
          title: 'Order dupliceren',
          confirmText: 'Order dupliceren',
        },
      });
    },
    async generatePackingLists() {
        const orderIds = this.selectedOrders.map(i => i.id);
        const orderNumbers = this.selectedOrders.map(i => i.order_number).join(", ");

        this.$buefy.dialog.confirm({
          title: 'Pakbonnen downloaden?',
          message: `Weet je zeker dat je pakbonnen voor de orders: ${orderNumbers} wil downloaden?`,
          cancelText: 'Annuleren',
          confirmText: 'Downloaden',
          type: 'is-success',
          hasIcon: true,
          onConfirm: () => this.downloadPackingSlips(orderIds),
        });
      },
      async downloadPackingSlips(orderIds) {
        try {
          this.isLoading = true;
          const pdf = await this.$store.dispatch('order/packingSlipDoc', { order_ids: orderIds });
          download('pakbon.pdf', pdf, 'application/pdf', 'base64');
          this.$buefy.toast.open('Pakbonnen gegenereerd');
        } 
        catch (error) {
          this.$buefy.toast.open('Er is een fout opgetreden bij het genereren van de pakbonnen.');
        } 
        finally {
          this.isLoading = false;
        }
      },
  },
};
