<template>
  <main class="section">
    <div class="container">
      <div class="login columns is-mobile is-centered">
        <div class="column is-two-thirds-tablet is-half-widescreen box">
          <div v-if="isAuthenticated()" class="content">
            <template v-if="!restoreResult">
              <h1 class="title">Laden</h1>
            </template>
            <template v-else-if="restoreResult.status && restoreResult.status === 'success'">
              <h1 class="title">Koppeling succesvol</h1>
              <p>Je kunt nu Innosend gebruiken!</p>
              <b-button tag="router-link" :to="{ name: 'home' }" type="is-primary">Ga naar het dashboard</b-button>
            </template>
            <template v-else>
              <h1 class="title">Probleem met koppelen</h1>
              <p>Neem contact op met de klantenservice.</p>
            </template>
            <b-loading :active="!restoreResult" :is-full-page="true"></b-loading>
          </div>
          <div v-else class="content">
            <h1 v-if="serviceName" class="title">{{ serviceName }} koppelen aan Innosend</h1>
            <h1 v-else class="title">Koppeling afronden</h1>

            <p>
              Om de koppeling af te ronden heb je een innosend account nodig. Heb je er al een? Log dan in om door te
              gaan.
            </p>

            <p>
              <b-button expanded type="is-primary" @click="login">Inloggen</b-button>
            </p>

            <p class="has-text-centered">of</p>

            <p>
              <b-button expanded @click="register">Maak gratis een account</b-button>
            </p>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import { api } from '@/utils';

  export default {
    props: {
      serviceName: String
    },
    data() {
      return {
        restoreResult: null
      };
    },
    computed: {
      ...mapGetters({
        isAuthenticated: 'auth/isAuthenticated'
      })
    },
    methods: {
      ...mapActions({
        login: 'auth/login',
        register: 'auth/register'
      }),
      async performRestore() {
        const result = await api.serviceOnboardRestore();

        // Render error
        if (!result.status || result.status !== 'success') return;

        this.restoreResult = result;

        // Init again, mainly to reload services
        this.$store.dispatch('init');

        // We may have encountered a first login
        if (this.$store.state.account.user.is_first_login) {
          this.$store.commit('account/setFirstLogin', false);
          return this.$router.push({ name: 'first-login' });
        }

        // Redirect to the right settings page if known
        if (this.$route.query.service_type && ['courier', 'shop'].includes(this.$route.query.service_type))
          return this.$router.push({ name: 'services', params: { type: this.$route.query.service_type } });

        // In all other cases, stay on this page to render the generic onboard result page
      },
      handleAuthenticated() {
        // Make sure we've got a user
        if (this.$store.state.account.user === null) {
          const unwatch = this.$store.watch(
            () => this.$store.state.account.user,
            () => {
              unwatch();
              if (this.$store.state.account.user) return this.performRestore();
            }
          );
        } else if (this.$store.state.account.user) return this.performRestore();
      }
    },
    created() {
      if (this.isAuthenticated()) {
        this.handleAuthenticated();
      }
    }
  };
</script>
