<template>
  <div class="dashboard-tab">
    <div class="level dashboard-controls">
      <div class="level-left">
        <slot name="controls"></slot>

        <div v-if="selectedItems.length > 0" class="level-item has-text-grey">Selectie: {{ selectedItems.length }}</div>
      </div>

      <div class="level-right">
        <div class="field has-addons level-item">
          <div class="control">
            <label class="is-sr-only" for="search-field"> Zoek {{ itemName }} </label>
            <input
              id="search-field"
              class="input"
              :placeholder="`Zoek ${itemName}`"
              type="search"
              @input="handleFilter($event.target.value)"
            />
          </div>
          <div class="control">
            <button class="button">
              <span class="icon">
                <font-awesome-icon fixed-width icon="search" />
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="hasUpdate" class="data-update-notification">
      <div class="notification is-dark is-condensed">
        <div class="media is-align-items-center">
          <div class="media-content">Nieuwe {{ itemNamePlural }} beschikbaar</div>
          <div class="media-right">
            <b-button outlined size="is-small" type="is-white" @click="updateView"> Data laden </b-button>
          </div>
        </div>
      </div>
    </div>
    <b-table
      ref="table"
      backend-pagination
      backend-sorting
      checkable
      :checked-rows.sync="selectedItems"
      class="is-nowrap"
      :current-page="view?.filter?.page ? view.filter.page : 1"
      :custom-detail-row="detailed"
      :data="data"
      detail-key="id"
      :detailed="detailed"
      :hoverable="true"
      :loading="isLoading"
      :mobile-cards="false"
      paginated
      :pagination-simple="true"
      :per-page="meta.page_size"
      :row-class="(row, index) => `tw-group${index % 2 === 0 ? ' even' : ' odd'}`"
      :show-detail-icon="false"
      :striped="true"
      :total="meta.total"
      @page-change="handlePageChange"
      @sort="handleSort"
    >
      <slot name="table"></slot>
      <template v-slot:detail="props">
        <slot :index="props.index" name="table-detail" :row="props.row"></slot>
      </template>
      <template #empty>
        <slot name="table-empty"> Geen {{ itemNamePlural }} gevonden </slot>
      </template>
    </b-table>
  </div>
</template>

<script>
  export default {
    props: {
      view: Object,
      itemName: String,
      itemNamePlural: String,
      defaultSort: Array,
      loadView: Function,
      updateView: Function,
      detailed: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        filter: {},
        selectedItems: [],
        debounceTimeoutId: null,
        debounce: 300,
      };
    },
    computed: {
      isLoading() {
        return this.view.isLoading;
      },
      meta() {
        let defaultData = {
          total: 0,
          page_size: 25,
          page: 1,
        };
        if (this.view.total) defaultData.total = this.view.total;
        return {
          ...defaultData,
          ...this.view.data?.meta,
        };
      },
      data() {
        return this.view.data?.data || [];
      },
      hasUpdate() {
        return !!this.view.update;
      },
    },
    methods: {
      async update(page) {
        this.loadView(page, this.filter);
      },
      handleFilter(filter) {
        if (this.debounceTimeoutId) clearTimeout(this.debounceTimeoutId);

        this.debounceTimeoutId = setTimeout(() => {
          if (!filter) delete this.filter.search;
          else this.filter.search = filter;
          this.update(this.meta.page);
        }, this.debounce);
      },
      handlePageChange(page) {
        this.update(page);
        // this.$store.dispatch('order/cachePage', page)
      },
      handleSort(field, order) {
        if (order === 'desc') field = '-' + field;
        this.filter.sort = field;
        this.update(this.meta.page);
        this.$emit('sort', field);
      },
      toggleDetails(row) {
        this.$refs.table.toggleDetails(row);
      },
      isVisibleDetailRow(row) {
        return this.$refs.table.isVisibleDetailRow(row);
      },
    },
    created() {
      if (this.defaultSort) this.handleSort(...this.defaultSort);
      else this.handlePageChange(this?.view?.filter?.page || 1);
    },
    watch: {
      selectedItems(newVal) {
        this.$emit('select', newVal);
      },
      data(newVal) {
        this.selectedItems = this.selectedItems.filter(id => newVal.find(item => item.id === id));
      },
    },
  };
</script>
