<template>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">{{ title }}</p>
      <button aria-label="Sluiten" class="delete" type="button" @click="cancel"></button>
    </header>
    <section class="modal-card-body">
      <b-field label="Papierformaat">
        <b-radio-button
          v-for="(option, key) in formatOptions"
          :key="key"
          v-model="format"
          :native-value="key"
          type="is-primary"
        >
          {{ option.label }}
        </b-radio-button>
      </b-field>
      <div>
        <h4 class="label">Indeling van de eerste pagina</h4>
        <template v-if="formatOptions[format].positions">
          <!-- TODO: Reimplement with fieldset + checboxes for a better keyboard and screen reader experience -->
          <!-- TODO: Maybe also preselect default positions, for less clicks? -->
          <p class="help mb-3">
            Door hieronder posities van labels op de eerste pagina aan te vinken, kan je een vel waarvan al een paar
            labels gebruikt zijn als eerste blad gebruiken. Eventuele volgende pagina's worden van links naar rechts en
            boven naar beneden ingevuld.
          </p>
          <div class="box positionLabelContainer">
            <div class="columns">
              <div class="column">
                <div class="positionLabel" @click="handlePositionClicked('upperLeft')">
                  <b-icon v-if="positions.upperLeft === true" icon="check" size="is-large" type="is-success"></b-icon>
                </div>
              </div>
              <div class="column">
                <div class="positionLabel" @click="handlePositionClicked('upperRight')">
                  <b-icon v-if="positions.upperRight === true" icon="check" size="is-large" type="is-success"></b-icon>
                </div>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <div class="positionLabel" @click="handlePositionClicked('lowerLeft')">
                  <b-icon v-if="positions.lowerLeft === true" icon="check" size="is-large" type="is-success"></b-icon>
                </div>
              </div>
              <div class="column">
                <div class="positionLabel" @click="handlePositionClicked('lowerRight')">
                  <b-icon v-if="positions.lowerRight === true" icon="check" size="is-large" type="is-success"></b-icon>
                </div>
              </div>
            </div>
          </div>
        </template>
        <p v-else>
          Bij het {{ formatOptions[format].name }} formaat wordt er maar één label per pagina afgedrukt, en kan de
          paginaindeling niet worden ingesteld.
        </p>
      </div>
    </section>
    <footer class="modal-card-foot buttons is-right">
      <button v-if="cancelText" class="button" type="button" @click="cancel">
        {{ cancelText }}
      </button>
      <b-button type="is-primary" @click="confirm">
        {{ confirmText }}
      </b-button>
    </footer>
  </div>
</template>

<script>
  export default {
    props: {
      parameters: {
        type: Object,
        default: () => ({})
      },
      title: {
        type: String,
        default: 'Labels downloaden'
      },
      cancelText: {
        type: String,
        default: 'Annuleren'
      },
      confirmText: {
        type: String,
        default: 'OK'
      },
      onCancel: Function,
      onConfirm: Function
    },
    data() {
      return {
        format: this.parameters.format || 'a4',
        formatOptions: {
          a6: { name: 'A6', label: 'A6 (1 per pagina)', positions: false },
          a4: { name: 'A4', label: 'A4 (4 per pagina)', positions: true }
        },
        positions: {
          upperLeft: false,
          upperRight: false,
          lowerLeft: false,
          lowerRight: false
        }
      };
    },
    computed: {
      labelCount() {
        return this.parameters.labelIds?.length || 0;
      }
    },
    methods: {
      confirm() {
        if (this.onConfirm)
          this.onConfirm({
            ...this.parameters,
            positions: this.formatOptions[this.format].positions ? this.positions : {},
            format: this.format
          });
        this.$parent.close();
      },
      cancel() {
        if (this.onCancel) this.onCancel();
        this.$parent.close();
      },
      handlePositionClicked(position) {
        // TODO make it so that the user can select where they want but the first selectable goes to false
        const numTrue = Object.values(this.positions).filter(a => a === true).length;
        if (numTrue < this.labelCount) {
          this.positions[position] = !this.positions[position];
        } else if (numTrue >= this.labelCount && this.positions[position] === true) {
          this.positions[position] = !this.positions[position];
        }
      }
    }
  };
</script>
