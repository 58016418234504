import { api } from '@/utils';
import hash from 'object-hash';

const state = {
  all: [],
  views: {
    orderTab: { isLoading: false, data: null, update: null, total: 0 },
    otherTab: { isLoading: false, data: null, update: null, total: 0 },
  },
};

const getters = {};

const actions = {
  // Get single orders
  async getOrder({ state, commit }, orderId) {
    let order = state.all.find(o => o.id === orderId);
    if (order) return order;
    let result = await api.getOrder(orderId);
    commit('setOrder', result.data);
    return result.data;
  },
  async searchOrder({ commit }, filters) {
    let order = state.all.find(o => Object.keys(filters).some(key => o[key] === filters[key]));
    if (order) return order;
    const result = await api.searchOrder(filters);
    commit('setOrder', result.data);
    return result.data;
  },
  // Create + update orders
  async createOrder({ commit, dispatch }, order) {
    const result = await api.createOrder(order);
    if (result.status === 'success') {
      commit('setOrder', result.data);
      dispatch('reloadViews', { silent: false });
      return result.data;
    }
  },
  async createOrderBulk({ commit, dispatch }, orders) {
    const result = await api.createOrderBulk(orders);
    for (const order of result.data) commit('setOrder', order);

    dispatch('reloadViews', { silent: false });
    return result;
  },
  async generateTestOrders({ commit, dispatch }) {
    const result = await api.generateTestOrders();
    if (result.status === 'success') {
      for (const order of result.data) commit('setOrder', order);

      dispatch('reloadViews', { silent: false });
      return result;
    }
  },
  async resetOrders({ commit }, orderIds) {
    const orders = await api.resetOrders(orderIds);
    commit('setOrders', orders);
  },
  async update({ commit, dispatch }) {
    commit('setLoading', true);
    try {
      await api.update('shop');
    } catch (e) {
      console.error(e);
    } finally {
      dispatch('reloadViews', { silent: false });
    }
  },
  async updateBulkOrders({ commit }, data) {
    const result = await api.updateBulkOrders(data);
    if (result.status === 'success') {
      const orderIds = result.orders.map(order => order.id);
      commit('deleteOrders', orderIds);
    }
  },
  async packingSlipDoc(_, data) {
    const result = await api.packingSlipDoc(data);
    if (result.status === 'success') return result.file;
  },
  async updateOrder({ commit }, order) {
    const result = await api.updateOrder(order);
    if (result.status === 'success') {
      if (result.data.id != order.id) commit('deleteOrders', [order.id]);
      commit('setOrder', result.data);
      return result.data;
    }
  },
  async addShipmentsToOrdersAction({ commit }, data) {
    commit('addShipmentsToOrders', data);
  },
  async validateOrders({ dispatch }, organisation_id) {
    const result = await api.validateOrders({ organisation_id: organisation_id });
    if (result.status === 'success') dispatch('reloadViews', { silent: false });
  },
  // View controls
  async loadView_({ commit, dispatch, state }, { view, filter = undefined, silent = false }) {
    // Don't need to be loading more than once
    if (state.views[view].isLoading) return;

    if (!silent) commit('setViewIsLoading', { view, isLoading: true });

    commit('setViewFilter', { view, filter });

    let result;
    try {
      result = await api.getOrders(filter);
    } finally {
      // Don't catch the error, so the caller can handle it
      if (!silent) commit('setViewIsLoading', { view, isLoading: false });
    }

    if (silent) commit('setViewUpdate', { view, data: result });
    else commit('setViewData', { view, data: result });

    // TODO: We do not need to do this this often…
    dispatch('loadViewTotals', { view, filter });

    // Store copy for easy retrieval
    commit('setOrders', result.data);
  },
  async loadView({ dispatch }, { view, filter }) {
    await dispatch('loadView_', { view, filter, silent: false });
  },
  async loadViewTotals({ commit }, { view, filter }) {
    const result = await api.getOrderCount(filter);
    commit('setViewTotal', { view, total: result.data });
  },
  reloadView({ state, dispatch }, { view, silent = false }) {
    dispatch('loadView_', { view, silent, filter: state.views[view].filter });
  },
  reloadViews({ state, dispatch }, { silent = false }) {
    for (let view in state.views) dispatch('loadView_', { view, silent, filter: state.views[view].filter });
  },
  updateView({ commit, state }, { view }) {
    commit('setViewData', { view, data: state.views[view].update });
    commit('setViewUpdate', { view, data: null });
  },
};

const mutations = {
  setOrder(state, order) {
    const idx = state.all.findIndex(o => o.id === order.id);
    if (idx >= 0) state.all.splice(idx, 1);
    state.all.push(order);
  },
  setOrders(state, orders) {
    for (let order of orders) {
      const idx = state.all.findIndex(o => o.id === order.id);
      if (idx >= 0) state.all.splice(idx, 1);
      state.all.push(order);
    }
  },
  deleteOrders(state, orderIds) {
    for (let id of orderIds) {
      let idx = state.all.findIndex(o => o.id === id);
      if (idx >= 0) state.all.splice(idx, 1);
    }
  },
  addShipmentsToOrders(state, orderShipmentIdPairs) {
    orderShipmentIdPairs.forEach(({ order_id, shipment_id }) => {
      const order = state.all.find(order => order.id === order_id);
      if (order) order.shipment_ids.push(shipment_id);
    });
  },
  // View controls
  setViewData(state, { view, data }) {
    state.views[view].data = data;
  },
  setViewFilter(state, { view, filter }) {
    state.views[view].filter = filter;
  },
  setViewIsLoading(state, { view, isLoading }) {
    state.views[view].isLoading = isLoading;
  },
  setViewTotal(state, { view, total }) {
    state.views[view].total = total;
  },
  setViewUpdate(state, { view, data }) {
    if (hash(state.views[view].data) !== hash(data)) state.views[view].update = data;
    else state.views[view].update = null;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
