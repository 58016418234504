import { api } from '@/utils';

const state = {
  all: [],
  loading: false
};

const getters = {
  all: state => state.all,
  ticketById: state => id => state.all.find(ticket => ticket.id === id),
  ticketByLabelId: state => label_id => state.all.find(ticket => ticket.label.id === label_id)
};

const actions = {
  async init({ dispatch }) {
    await dispatch('getTickets');
  },

  async getTickets({ commit }) {
    commit('setLoading', true);
    try {
      const apiResponse = await api.getTickets();
      if (apiResponse.status === 'success') {
        commit('setTickets', apiResponse.response.tickets);
      }
    } catch (e) {
      console.error(e);
    } finally {
      commit('setLoading', false);
    }
  },
  async getSingleTicket(context, ticketId) {
    return await api.getSingleTicket(ticketId);
  },
  async createTicket({ dispatch }, data) {
    const response = await api.createTicket(data);
    if (response.status === 'success');
    await dispatch('getTickets');
    return response;
  },
  async reply({ dispatch }, { ticketId, reply }) {
    const apiResponse = await api.replyTicket(ticketId, reply);
    if (apiResponse.status === 'success') dispatch('getTickets');
    return apiResponse;
  },
  async update({ dispatch }, { ticketId, update }) {
    const apiResponse = await api.updateTicket(ticketId, update);
    if (apiResponse.status === 'success') dispatch('getTickets');
    return apiResponse;
  }
};

const mutations = {
  setLoading(state, loading) {
    state.loading = loading;
  },
  setTickets(state, tickets) {
    state.all = tickets;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
