<template>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">{{ title }}</p>
    </header>
    <section class="modal-card-body">
      <div class="content">
        <template v-if="Array.isArray(message)">
          <template v-for="(m, idx) in message">
            <ul v-if="Array.isArray(m)" :key="idx">
              <li v-for="item in m" :key="item">
                {{ item }}
              </li>
            </ul>
            <p v-else :key="idx">
              {{ m }}
            </p>
          </template>
          <a v-if="title === 'Quotum overschreden'" class="button" href="/settings/plans">Naar abonnementen</a>
        </template>
        <p v-else>
          <!-- Assume message is a string -->
          {{ message }}
        </p>
      </div>
      <b-collapse v-if="details && details.length > 0" aria-id="errorDetails" :open="false">
        <template #trigger="props">
          <a aria-controls="errorDetails">
            <b-icon :icon="!props.open ? 'chevron-down' : 'chevron-up'"></b-icon>
            {{ !props.open ? 'Details weergeven' : 'Details verbergen' }}
          </a>
        </template>
        <div class="content">
          <template v-if="Array.isArray(details)">
            <pre><div v-for="(item, idx) in details" :key="idx">{{ item }}</div></pre>
          </template>
          <pre v-else>
                        <!-- Assume details is a string -->
                        {{ details }}
                    </pre>
        </div>
      </b-collapse>
    </section>
    <footer class="modal-card-foot buttons is-right">
      <button v-if="cancelText" class="button" type="button" @click="cancel">
        {{ cancelText }}
      </button>
      <b-button type="is-primary" @click="confirm">
        {{ confirmText }}
      </b-button>
    </footer>
  </div>
</template>

<script>
  /**
   * @typedef {Object} ErrorDialogProps
   * @property {string} [title]
   * @property {string|string[]} [message]
   * @property {string|string[]} [details]
   * @property {string} [cancelText]
   * @property {string} [confirmText]
   * @property {Function} [onCancel]
   * @property {Function} [onConfirm]
   */
  export default {
    props: {
      title: String,
      message: null,
      details: null,
      cancelText: String,
      confirmText: {
        type: String,
        default: 'OK'
      },
      onCancel: Function,
      onConfirm: Function
    },
    methods: {
      confirm() {
        this.onConfirm?.();
        this.$parent.close();
      },
      cancel() {
        this.onCancel?.();
        this.$parent.close();
      }
    }
  };
</script>
