import { ModalProgrammatic as Modal } from 'buefy';
import moment from 'moment';
import ErrorDialog from '@/components/modals/ErrorDialog';

import store from '@/store';
import { clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function formatStatus(status) {
  const mapping = {
    incomplete: 'Niet volledig',
    ready: 'Klaar voor verwerken',
    label: 'Niet geprint',
    printed: 'Geprint',
    transit: 'Onderweg naar sorteercentrum',
    depot: 'In sorteercentrum',
    courier: 'Chauffeur is onderweg',
    delivered: 'Bezorgd',
    picked_up_servicepoint: 'Opgehaald servicepunt',
    delivered_servicepoint: 'Bezorgd servicepunt',
    check_required: 'controleer zending',
  };

  const s = String(status).toLowerCase();

  if (mapping[s]) return mapping[s];
  else if (status) return status;
  return 'Onbekend';
}

// TODO: Rename. This doesn't just return a base64 string, but actually an urlencoded base64 version of the file
// https://stackoverflow.com/questions/36280818/how-to-convert-file-to-base64-in-javascript
export async function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

export function debounce(callback, delay = 3000, providedTimeout = null) {
  let timeout = providedTimeout;
  return function () {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      callback.apply(this, arguments);
    }, delay);
  };
}

export function download(filename, text, mimetype = 'text/plain', encoding = 'charset=utf-8') {
  let element = document.createElement('a');
  element.setAttribute('href', 'data:' + mimetype + ';' + encoding + ',' + encodeURIComponent(text));
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

function isIOS() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  return /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
}

/**
 * copyTextToClipboard function courtesy of Dean Taylor on stackoverflow, with modifications
 * https://stackoverflow.com/questions/400212/how-do-i-copy-to-the-clipboard-in-javascript
 *
 * TODO: Rewrite using navigator.clipboard (https://developer.mozilla.org/en-US/docs/Web/API/Clipboard/writeText)
 */
export function copyTextToClipboard(text) {
  let textArea = document.createElement('textarea');

  // Make sure textarea is invisible
  textArea.style.position = 'fixed';
  textArea.style.top = 0;
  textArea.style.left = 0;
  textArea.style.width = '2em';
  textArea.style.height = '2em';
  textArea.style.padding = 0;
  textArea.style.border = 'none';
  textArea.style.outline = 'none';
  textArea.style.boxShadow = 'none';
  textArea.style.background = 'transparent';
  textArea.style.fontSize = '16px';
  textArea.value = text;
  document.body.appendChild(textArea);

  // Select and copy contents
  if (isIOS()) {
    let range = document.createRange();
    range.selectNodeContents(textArea);
    let selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
    textArea.setSelectionRange(0, 999999);
  } else {
    textArea.select();
  }

  try {
    document.execCommand('copy');
  } catch (err) {
    return false;
  }

  // Clean up
  document.body.removeChild(textArea);

  return true;
}

export function getMoment(value) {
  if (moment.isMoment(value)) return value;
  return moment(value);
}

export async function awaitIfPromise(p) {
  if (typeof p === 'object' && typeof p.then === 'function') return await p;
  return p;
}

export function pluralize(amount, single, plural) {
  if (amount === 1) return single;
  return plural;
}

export function reportLabelError(result, orderName = 'Order') {
  const orders = store.state.order.all;
  let errors = {
    details: [],
    messages: [],
    unknown_type: [],
    unknown_orders: {
      duplicate: 0,
      incomplete: 0,
      no_access: 0,
      unknown_type: 0,
    },
  };

  if (result.errors) {
    for (let error of result.errors) {
      const order = orders.find(s => s.id === error.order_id) || {};

      const order_number = order.order_number || '(nummer onbekend)';

      if (order.order_number) {
        if (error.type === 'duplicate') errors.messages.push(`${orderName} ${order_number} heeft al een label.`);
        else if (error.type === 'exception') errors.messages.push(`${orderName} ${order_number}: ${error.message}`);
        else if (error.type === 'incomplete') errors.messages.push(`${orderName} ${order_number} is niet volledig.`);
        else if (error.type === 'no_access')
          errors.messages.push(
            `Jouw account heeft geen rechten om labels te maken voor ${orderName.toLowerCase()} ${order_number}.`,
          );
        else if (error.type === 'blocked') {
          errors.messages.push(`${orderName} ${order_number} Land wordt niet ondersteund door Innosend.`);
        } else if (error.type === 'is_pending') {
          errors.messages.push(
            `${orderName} ${order_number} Heeft de pending status, wat betekent dat er geen label aangemaakt kan worden. Je kan dit bericht voorkomen door de status van de order in de webshop aan te passen.`,
          );
        } else if (error.type === 'quotum') {
          errors.messages.push(error.message);
        } else errors.unknown_type.push(order_number);
      } else {
        if (error.type === 'duplicate' || error.type === 'incomplete' || error.type === 'no_access')
          errors.unknown_orders[error.type]++;
        else errors.unknown_orders.unknown_type++;
      }

      if (error.details) errors.details.push(error.details);
    }
  }

  if (errors.unknown_orders.duplicate > 0)
    errors.messages.push(
      pluralize(
        errors.unknown_orders.duplicate,
        `1 ${orderName.toLowerCase()} zonder ordernummer heeft al een label.`,
        `${errors.unknown_orders.duplicate} ${orderName.toLowerCase()}s zonder ordernummer hebben al een label.`,
      ),
    );

  if (errors.unknown_orders.incomplete > 0)
    errors.messages.push(
      pluralize(
        errors.unknown_orders.incomplete,
        `1 ${orderName.toLowerCase()} zonder ordernummer is niet volledig.`,
        `${errors.unknown_orders.incomplete} ${orderName.toLowerCase()}s zonder ordernummer is niet volledig.`,
      ),
    );

  if (errors.unknown_orders.no_access > 0)
    errors.messages.push(
      pluralize(
        errors.unknown_orders.no_access,
        `Jouw account heeft geen rechten om labels te maken voor 1 ${orderName.toLowerCase()} zonder ordernummer.`,
        `Jouw account heeft geen rechten om labels te maken voor ${
          errors.unknown_orders.no_access
        } ${orderName.toLowerCase()}s zonder ordernummer.`,
      ),
    );

  let message = [];

  if (result.data && result.data.length > 0)
    message.push(
      pluralize(
        result.data.length,
        'Er is 1 label successvol aangemaakt.',
        `Er zijn ${result.data.length} labels successvol aangemaakt.`,
      ),
    );

  if (result.errors && result.errors.length > 0) {
    let errorMessage = pluralize(
      result.errors.length,
      'Het aanmaken van 1 label is mislukt.',
      `Het aanmaken van ${result.errors.length} label is mislukt.`,
    );
    message.push(`${errorMessage} Hieronder staat meer informatie over de fout(en).`);
  } else if (result.type === 'timeout')
    message.push(
      `Het aanmaken van labels duurde langer dan verwacht. Probeer over een paar minuten de pagina te vernieuwen.`,
    );
  else if (result.type === 'unpaid_invoices')
    message.push(
      `Je hebt helaas achterstallige betalingen in je account. Je kunt geen zendingen meer aanmaken totdat de facturen zijn betaald. Bel of mail ons gerust voor vragen op 050 211 09 22 of administratie@innosend.eu`,
    );
  else if (result.type === 'no_labels')
    message.push(
      'We kregen geen labels terug, Dit kan komen door een fout bij de vervoerder of een fout bij het aanmaken van de labels. Probeer het later nog eens.',
    );
  else message.push(`Er is een onbekende fout opgetreden.`);

  if (errors.messages.length > 0) message.push(errors.messages);

  if (errors.unknown_type.length > 0 || errors.unknown_orders.unknown_type > 0) {
    const count = errors.unknown_type.length + errors.unknown_orders.unknown_type;

    if (errors.unknown_orders.unknown_type > 0)
      errors.unknown_type.push(
        pluralize(
          errors.unknown_orders.unknown_type,
          `1 ${orderName.toLowerCase()} zonder ordernummer`,
          `${errors.unknown_orders.unknown_type} ${orderName.toLowerCase()}s zonder ordernummer`,
        ),
      );

    message.push(`Er konden
                ${pluralize(count, `1 ${orderName.toLowerCase()}`, `${count} ${orderName.toLowerCase()}s`)} 
                niet worden aangemeld bij vervoerder. Controleer of de volgende gegevens correct zijn ingevoerd:`);
    message.push(['Postcode', 'Huisnummer', 'Adresregels', 'E-mailadres']);
    message.push('Voor de volgende orders:');
    message.push(errors.unknown_type);
  }

  Modal.open({
    parent: this,
    component: ErrorDialog,
    hasModalCard: true,
    trapFocus: true,
    props: {
      title: 'Er ging iets mis',
      message: message,
      details: errors.details,
    },
  });
}

export function reportPlanError(result) {
  // result.message.push('ga naar instellingen om een plan te kiezen')
  Modal.open({
    parent: this,
    component: ErrorDialog,
    hasModalCard: true,
    trapFocus: true,
    props: {
      title: 'Quotum overschreden',
      message: [result.message],
    },
  });
}

export function reportSimpleError(result) {
  Modal.open({
    parent: this,
    component: ErrorDialog,
    hasModalCard: true,
    trapFocus: true,
    props: {
      title: result.title,
      message: [result.message],
    },
  });
}

export function reportBillingError(options) {
  Modal.open({
    parent: this,
    component: ErrorDialog,
    hasModalCard: true,
    trapFocus: true,
    props: {
      title: 'Geen automatische incasso',
      message: [options.message],
      confirmText: 'Activeer incasso',
      cancelText: 'Annuleren',
      onConfirm: options.onConfirm,
    },
  });
}

/**
 * Use this function to report an error to the user.
 * @throws
 * @param {import('@/components/modals/ErrorDialog.vue').ErrorDialogProps} props
 */
export function reportGenericError(
  props = {
    title: 'Er ging iets mis',
    message: 'Er is een onbekende fout opgetreden.',
    confirmText: 'Ok',
    cancelText: undefined,
    onConfirm: undefined,
    onCancel: undefined,
    details: undefined,
  },
) {
  Modal.open({
    parent: this,
    component: ErrorDialog,
    hasModalCard: true,
    trapFocus: true,
    canCancel: ['escape', 'outside'],
    props,
  });
}

export function reportReturnLabelWarning(options) {
  Modal.open({
    parent: this,
    component: ErrorDialog,
    hasModalCard: true,
    trapFocus: true,
    props: {
      title: 'Let op! Verzendopties zijn niet toegestaan',
      message:
        'Er zijn voor deze zending geen verzendopties toegestaan. Weet je zeker dat je een retourlabel wilt aanmaken?',
      confirmText: 'Retourlabel aanmaken',
      cancelText: 'Annuleren',
      onConfirm: options.onConfirm,
    },
  });
}

export function reportLabelWarning(result, options) {
  const allOrders = store.state.order.all;
  const allLabels = store.state.label.all;

  let duplicate = [];
  let incomplete = [];
  let duplicate_unknown = 0;
  let incomplete_unknown = 0;
  let other = 0;
  let return_not_supported = 0;

  let message = [];
  let title = 'Waarschuwing!';

  if (result.errors) {
    for (let error of result.errors) {
      let order = allOrders.find(order => order.id === error.order_id) || {};
      let orderNumber = order.order_number;

      //if we cannot find the ordernumber in the order list, we try to find it in the label list
      if (!orderNumber) {
        orderNumber = allLabels.find(label => label.order_id === error.order_id)?.order_number;
      }

      if (orderNumber) {
        switch (error.type) {
          case 'duplicate':
            duplicate.push(orderNumber);
            break;
          case 'incomplete':
            incomplete.push(orderNumber);
            break;
          case 'return_not_supported':
            return_not_supported++;
            break;
          default:
            other++;
            break;
        }
      } else {
        switch (error.type) {
          case 'duplicate':
            duplicate_unknown++;
            break;
          case 'incomplete':
            incomplete_unknown++;
            break;
          case 'return_not_supported':
            return_not_supported++;
            break;
          default:
            other++;
            break;
        }
      }
    }
  }

  if (duplicate_unknown > 0) duplicate.push(`${duplicate_unknown} zonder ordernummer`);

  if (incomplete_unknown > 0) incomplete.push(`${incomplete_unknown} zonder ordernummer`);

  if (duplicate.length > 0) {
    message.push(
      pluralize(
        duplicate.length + (duplicate_unknown > 0 ? duplicate_unknown - 1 : 0),
        `Je print een nieuw label met een nieuw trackingnummer!`,
        `Je print nieuwe labels met een nieuwe trackingnummers!`,
      ),
    );
    message.push(
      pluralize(
        duplicate.length + (duplicate_unknown > 0 ? duplicate_unknown - 1 : 0),
        `De volgende order heeft al een label:`,
        `De volgende orders hebben al een label:`,
      ),
    );
    message.push(duplicate);
  }

  if (incomplete.length > 0) {
    message.push(
      pluralize(
        incomplete.length + (incomplete_unknown > 0 ? incomplete_unknown - 1 : 0),
        `De volgende order is niet volledig:`,
        `De volgende orders zijn niet volledig:`,
      ),
    );
    message.push(incomplete);
  }

  if (return_not_supported > 0) {
    title = 'Retour niet mogelijk';
    message.push('De vervoerder van deze zending ondersteunt geen retourzendingen vanuit dit land.');
    message.push('Wil je als alternatief een DHL label aanmaken?');
  } else {
    if (other > 0)
      message.push(pluralize(other, 'Er is een onbekend probleem.', `Er zijn ${other} onbekende problemen.`));

    if (duplicate.length > 0 && !incomplete.length && !other) {
      message.push('Weet je zeker dat je hiervoor nog een label wilt aanmaken?');
      title = 'LET OP! Order heeft al een label!';
    } else {
      message.push('Weet je zeker dat je hiervoor labels wilt aanmaken?');
    }
  }

  options.title = title;
  options.message = message;

  Modal.open({
    parent: this,
    component: ErrorDialog,
    hasModalCard: true,
    trapFocus: true,
    props: options,
  });
}

export function reportTrackingError(errors) {
  let message = [];
  errors.forEach(element => {
    message.push(element);
  });
  Modal.open({
    parent: this,
    component: ErrorDialog,
    hasModalCard: true,
    trapFocus: true,
    props: {
      title: 'Tracking fout',
      message: message,
    },
  });
}

export async function printLabel({ labelIds, target = 'pdf', format = 'a6', positions = {}, sort = null }) {
  if (target === 'pdf') {
    // Store method ensures labelIds is a list
    const file = await store.dispatch('label/getPdfLabels', { labelIds, format, positions, sort });
    download('labels.pdf', file, 'application/pdf', 'base64');
  } else if (target === 'printer') {
    const printerClientId = store.state.app.printerClient.id;

    if (!printerClientId) {
      // TODO: Report to user?
      console.error('client not active');
      return;
    }

    // Store method ensures labelIds is a list
    await store.dispatch('label/printLabels', { labelIds, sort, format, positions, printerClientId });
  } else {
    // Unknown target
    console.error('unknown print target');
  }
}

export function cn(...classes) {
  return twMerge(clsx(classes));
}

export const filterChangedValues = (oldValues, newValues) => {
  const changedValues = {};

  const deepCompareAndAssign = (oldObj, newObj, resultObj) => {
    for (const [key, value] of Object.entries(newObj)) {
      if (value && typeof value === 'object' && !Array.isArray(value)) {
        resultObj[key] = deepCompareAndAssign(oldObj[key] || {}, value, {});
        if (Object.keys(resultObj[key]).length === 0) {
          delete resultObj[key];
        }
      } else if (oldObj[key] !== value) {
        resultObj[key] = value;
      }
    }
    return resultObj;
  };

  return deepCompareAndAssign(oldValues, newValues, changedValues);
};
