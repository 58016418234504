<template>
  <ul class="tw-pb-32 md:tw-pb-0">
    <template v-for="{ courier, locations } in pickup_points_by_courier">
      <li
        v-for="location in locations"
        :key="location.id"
        :ref="`location-list-item-${location.id}`"
        class="tw-p-4 tw-gap-2 hover:tw-bg-primary/80 tw-border-0 tw-border-solid tw-cursor-pointer !tw-border-b tw-border-gray-200"
        :class="{
          '!tw-bg-primary tw-text-white': selectedLocation?.id === location.id,
        }"
        @click="setSelectedLocation(location)"
      >
        <div>
          <div class="tw-space-y-4">
            <h1 class="tw-text-lg tw-font-semibold">{{ location.name }}</h1>
            <LocationAddress :location="location" />
          </div>
          <div
            class="tw-h-0 tw-transition-[padding,height]"
            :class="{
              'tw-h-auto': selectedLocation?.id === location.id,
            }"
          >
            <b-collapse animation="slide" :open="selectedLocation?.id === location.id">
              <!-- hours -->
              <div class="mt-4">
                <OpeningHoursTable class="tw-w-full" :location="location" />
              </div>
            </b-collapse>
          </div>
        </div>
      </li>
    </template>
  </ul>
</template>

<script setup>
  import { inject, watch, nextTick, getCurrentInstance } from 'vue';
  import OpeningHoursTable from './OpeningHoursTable.vue';
  import LocationAddress from './LocationAddress.vue';

  const { proxy } = getCurrentInstance();
  const pickup_points_by_courier = inject('pickup_points_by_courier');
  const selectedLocation = inject('selectedLocation');
  const setSelectedLocation = inject('setSelectedLocation');

  let timeout;

  watch(selectedLocation, async newLocation => {
    if (newLocation) {
      await nextTick();
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        const element = proxy.$refs[`location-list-item-${newLocation.id}`][0];
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }, 500);
    }
  });
</script>
