<template>
  <!-- TODO multicolli -->
  <property class="has-tabular-nums" :copy-value="trackingNumber || trackingUrl">
    <a
      :href="trackingUrl"
      rel="noopener noreferrer"
      target="_blank"
      :title="trackingNumber || 'Trackingpagina'"
    >
      <template v-if="trackingNumber">
        {{ trackingNumber }}
      </template>
      <template v-else>
        Trackingpagina
        <span aria-label="Externe link" class="icon is-small has-text-grey" title="Externe link">
          <i aria-hidden="true" class="gg-external"></i>
        </span>
      </template>
    </a>
  </property>
</template>

<script>
  import Property from '@/components/properties/Property';

  export default {
    components: { Property },
    props: {
      trackingNumber: String,
      trackingUrl: String,
    },
  };
</script>
