<template>
  <main class="section">
    <b-progress></b-progress>
  </main>
</template>

<script>
  export default {
    methods: {
      handleAuthenticated() {
        if (this.$store.state.account.user.is_first_login) {
          this.$store.commit('account/setFirstLogin', false);
          this.$router.push({ name: 'first-login' });
        } else if (this.$route.params.to && this.$route.params.to.name != 'loading') {
          this.$router.push(this.$route.params.to);
        } else {
          this.$router.push({ name: 'home' });
        }
      }
    },
    watch: {
      '$store.state.account.user': {
        handler(value) {
          if (value) return this.handleAuthenticated();
        },
        immediate: true
      }
    }
  };
</script>
