<template>
  <div>
    <base-tab
      ref="basetab"
      :default-sort="defaultSort"
      :detailed="true"
      :item-name="itemName || 'label'"
      :item-name-plural="itemNamePlural || 'labels'"
      :loadView="loadView"
      :updateView="updateView"
      :view="view"
      @select="selectedLabels = $event"
      @sort="sort = $event"
    >
      <template #controls>
        <b-button
          class="level-item responsive-button"
          :disabled="isUpdating"
          icon-left="sync"
          :loading="isUpdating"
          @click="update"
        >
          Track labels
        </b-button>
        <div v-if="labelActions.includes('print')" class="level-item">
          <print-controls
            context="label"
            :labelIds="selectedLabels.map(l => l.id)"
            :orderIds="selectedLabels.map(l => l.order_id)"
          ></print-controls>
        </div>
        <div class="level-item">
          <b-field class="has-dropdown">
            <p class="control">
              <b-dropdown :disabled="isUpdating || !selectedLabels.length">
                <template #trigger>
                  <b-button
                    class="responsive-button"
                    icon-right="chevron-down"
                    label="Meer acties…"
                    :loading="isUpdating"
                  >
                  </b-button>
                </template>

                <b-dropdown-item
                  aria-role="listitem"
                  :disabled="isUpdating"
                  :loading="isUpdating"
                  style="display: flex; gap: 8px; align-items: center"
                  @click="generatePackingLists(selectedLabels)"
                >
                  <font-awesome-icon class="is-size-5" fixed-width icon="file-arrow-down" />
                  Pakbonnen downloaden
                </b-dropdown-item>

                <b-dropdown-item
                  aria-role="listitem"
                  :disabled="isUpdating"
                  :loading="isUpdating"
                  style="display: flex; align-items: center; gap: 8px"
                  @click="deactivateBulkLabels(selectedLabels)"
                >
                  <font-awesome-icon class="is-size-5" fixed-width icon="trash" />
                  Labels verwijderen
                </b-dropdown-item>

                <b-dropdown-item
                  v-if="labelActions.includes('set_delivered')"
                  aria-role="listitem"
                  style="display: flex; align-items: center; gap: 8px"
                  @click="deliverBulkLabels(selectedLabels)"
                >
                  <font-awesome-icon class="is-size-5" fixed-width icon="forward" />
                  Naar bezorgd
                </b-dropdown-item>
              </b-dropdown>
            </p>
          </b-field>
        </div>
      </template>
      <template #table>
        <b-table-column
          v-slot="props"
          cell-class="is-vcentered is-truncated is-15"
          field="order_number"
          label="Ordernummer"
          numeric
          sortable
        >
          <property class="has-tabular-nums" :copy-value="props.row.order_number">{{
            props.row.order_number
          }}</property>
        </b-table-column>
        <b-table-column v-slot="props" cell-class="is-vcentered" field="status" label="Status" sortable>
          <status :item="props.row" type="tag" />
        </b-table-column>
        <b-table-column v-slot="props" cell-class="is-vcentered is-truncated" field="name" label="Naam" sortable>
          <property :copy-value="props.row.name">{{ props.row.name }}</property>
        </b-table-column>
        <b-table-column
          v-slot="props"
          cell-class="is-vcentered is-truncated is-25"
          field="shipment_type"
          label="Verzendmethode"
          sortable
        >
          <courier format="short" :item="props.row" :show-warnings="false" />
        </b-table-column>
        <b-table-column v-slot="props" cell-class="is-vcentered" field="country" label="Land" sortable>
          <country flag format="short" :value="props.row.country"></country>
        </b-table-column>
        <b-table-column v-slot="props" cell-class="is-vcentered buttons is-right">
          <!-- Toggle in separete column (not in tracking-number). This solves numerous alignment issues and still looks OK if no label is multicolli -->
          <!-- TODO: How's a11y here? -->
          <a
            v-if="props.row.shipment.packages.length > 1"
            class="button is-text is-small"
            @click="togglePackages(props.row)"
          >
            <b-icon :icon="isVisiblePackages(props.row) ? 'chevron-up' : 'chevron-down'"></b-icon>
          </a>
        </b-table-column>
        <b-table-column
          v-slot="props"
          cell-class="is-vcentered is-truncated is-15"
          field="tracking_number"
          label="Trackingnummer"
        >
          <tracking-number
            v-if="props.row.shipment.tracking_url"
            :tracking-number="props.row.shipment.tracking_number"
            :tracking-url="props.row.shipment.tracking_url"
          />
          <tracking-number
            v-else
            :tracking-number="props.row.shipment.packages[0].tracking_number"
            :tracking-url="props.row.shipment.packages[0].tracking_url"
          />
        </b-table-column>
        <b-table-column v-slot="props" cell-class="is-vcentered" field="created_at" label="Aangemaakt" numeric sortable>
          {{ getCreatedAtTimeStamp(props.row) | humanizeDate({ day: 'numeric', month: 'short', year: 'numeric' }) }}
        </b-table-column>
        <b-table-column
          v-slot="props"
          cell-class="is-vcentered !tw-border-l-2 !tw-border-l group-[.odd]:tw-bg-[#fafafa] group-hover:!tw-bg-[whitesmoke] tw-sticky tw-right-0 tw-z-10 tw-bg-white buttons is-right"
        >
          <b-button
            aria-label="details"
            class="is-small"
            tag="router-link"
            title="details"
            :to="{ name: 'label', params: { labelId: props.row.id } }"
          >
            <font-awesome-icon class="icon" fixed-width icon="eye" />
          </b-button>

          <b-dropdown append-to-body position="is-bottom-left">
            <template #trigger>
              <button aria-label="acties" class="button is-small" title="acties">
                <font-awesome-icon class="icon" fixed-width icon="ellipsis-v" />
              </button>
            </template>

            <b-dropdown-item
              v-show="labelActions.includes('print') && printerClientIsReady"
              aria-role="listitem"
              @click="printLabel(props.row, 'printer')"
            >
              <b-icon icon="printer"></b-icon>
              Print
            </b-dropdown-item>
            <b-dropdown-item
              v-show="labelActions.includes('print')"
              aria-role="listitem"
              style="display: flex; align-items: center; gap: 8px"
              @click="printLabel(props.row, 'pdf')"
            >
              <font-awesome-icon class="is-size-5" fixed-width icon="file-pdf" />
              Download PDF
            </b-dropdown-item>

            <b-dropdown-item
              aria-role="listitem"
              style="display: flex; align-items: center; gap: 8px"
              @click="newOrderFromLabel(props.row)"
            >
              <font-awesome-icon class="is-size-5" fixed-width icon="copy" />
              Label dupliceren naar bestellingen
            </b-dropdown-item>
            <b-dropdown-item
              aria-role="listitem"
              style="display: flex; align-items: center; gap: 8px"
              @click="deactivateLabel(props.row)"
            >
              <font-awesome-icon class="is-size-5" fixed-width icon="trash" />
              Label verwijderen
            </b-dropdown-item>

            <b-dropdown-item
              v-if="labelActions.includes('set_delivered')"
              aria-role="listitem"
              style="display: flex; align-items: center; gap: 8px"
              @click="deliverLabel(props.row)"
            >
              <font-awesome-icon class="is-size-5" fixed-width icon="forward" />
              Naar bezorgd
            </b-dropdown-item>

            <template v-if="isAllowedToTicket(props.row) && labelActions.includes('create_ticket')">
              <b-dropdown-item v-if="!getTicket(props.row)" aria-role="listitem" has-link>
                <router-link
                  style="display: flex; align-items: center; gap: 8px"
                  :to="{ name: 'create_ticket', params: { labelId: props.row.id } }"
                >
                  <font-awesome-icon class="is-size-5" fixed-width icon="bolt-lightning" />
                  <span>Onderzoek aanvragen</span>
                </router-link>
              </b-dropdown-item>

              <b-dropdown-item v-else aria-role="listitem" has-link>
                <router-link :to="{ name: 'ticket-single', params: { ticketId: props.row.ticket.ticket_id } }">
                  <font-awesome-icon class="is-size-5" fixed-width icon="bolt-lightning" />
                  <span>Naar onderzoek</span>
                </router-link>
              </b-dropdown-item>
            </template>
          </b-dropdown>
        </b-table-column>
      </template>
      <template #table-detail="props">
        <tr v-for="packageItem in props.row.shipment.packages.slice(1)" :key="packageItem.tracking_number">
          <td colspan="7">&nbsp;<!-- Simple solution to make sure these rows have the correct height --></td>
          <td class="is-truncated is-15">
            <tracking-number :tracking-number="packageItem.tracking_number" :tracking-url="packageItem.tracking_url" />
          </td>
          <td colspan="2"></td>
        </tr>
      </template>
    </base-tab>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import BaseTab from './BaseTab';
  import Courier from '@/components/properties/Courier';
  import Property from '@/components/properties/Property';
  import Country from '@/components/properties/Country';
  import LabelMixin from '@/components/mixins/LabelMixin.js';
  import Status from '@/components/properties/Status';
  import TrackingNumber from '@/components/properties/TrackingNumber';
  import { getMoment, reportTrackingError } from '@/utils/functions';
  import PrintControls from '@/components/PrintControls.vue';

  const LABEL_ACTIONS = ['deactivate', 'print', 'set_delivered', 'create_ticket'];

  export default {
    components: { BaseTab, Country, Courier, PrintControls, Property, Status, TrackingNumber },
    mixins: [LabelMixin],
    props: {
      defaultSort: Array,
      labelActions: {
        type: Array,
        default: () => [],
        validator: value => value.every(val => LABEL_ACTIONS.includes(val)),
      },
      labelFilter: Object,
      labelTimestamp: [String, Function],
      viewName: {
        type: String,
        default: 'labelTab',
      },
      itemName: String,
      itemNamePlural: String,
    },
    data() {
      return {
        isUpdating: false,
        selectedLabels: [],
        formats: ['a4'],
        sort: null,
      };
    },
    computed: {
      ...mapState({
        view(state) {
          return state.label.views[this.viewName];
        },
        organisation: state => state.account.organisation,
      }),
      console: () => console,
    },
    methods: {
      isAllowedToTicket(label) {
        return (
          process.env.VUE_APP_IS_UVDESK_ENABLED &&
          label.invoice_items?.find(item => item.type === 'label_price') !== undefined
        );
      },
      updateView() {
        this.$store.dispatch('label/updateView', { view: this.viewName });
      },
      loadView(page, filter) {
        this.$store.dispatch('label/loadView', {
          view: this.viewName,
          filter: {
            ...filter,
            ...this.labelFilter,
            page,
          },
        });
      },
      async update() {
        this.isUpdating = true;
        try {
          await this.$store.dispatch('label/update');
        } catch (e) {
          const errors = e.response.errors;
          if (errors) {
            reportTrackingError(errors);
          }
        } finally {
          this.isUpdating = false;
        }
      },
      getTicket(label) {
        return label.ticket ? Object.keys(label.ticket).length > 0 : null;
      },
      getLabelTimestamp(label) {
        if (this.labelTimestamp instanceof Function) return getMoment(this.labelTimestamp(label));
        else if (this.labelTimestamp instanceof String) return getMoment(label[this.labelTimestamp]);
        else if (label.tracking_date) return getMoment(label.tracking_date);
        return getMoment(label.updated_at);
      },
      getCreatedAtTimeStamp(label) {
        return getMoment(label.created_at);
      },
      togglePackages(row) {
        this.$refs.basetab.toggleDetails(row);
      },
      isVisiblePackages(row) {
        return this.$refs.basetab.isVisibleDetailRow(row);
      },
    },
  };
</script>
