<template>
  <span v-if="type === 'property'">
    {{ item.status | format }}
    <b-tooltip v-if="isError" append-to-body multilined position="is-right" type="is-dark">
      <span class="icon has-text-danger">
        <i aria-hidden="true" class="gg-danger"></i>
      </span>
      <template v-slot:content>
        <status-info container-tag="ul" :info="item.status_info" item-tag="li" :no-class="noClass" />
      </template>
    </b-tooltip>
    <b-tooltip v-if="isWarning" append-to-body multilined position="is-right" type="is-dark">
      <span class="icon warning">
        <i aria-hidden="true" class="gg-danger"></i>
      </span>
      <template v-slot:content>
        <status-info container-tag="ul" :info="item.status_info" item-tag="li" :no-class="noClass" />
      </template>
    </b-tooltip>
    <b-tooltip v-if="isInfo" append-to-body multilined position="is-right" type="is-dark">
      <span class="icon has-text-info">
        <i aria-hidden="true" class="gg-info"></i>
      </span>
      <template v-slot:content>
        <status-info container-tag="ul" :info="item.status_info" item-tag="li" :no-class="noClass" />
      </template>
    </b-tooltip>
  </span>
  <span v-else-if="type === 'tag'">
    <b-tooltip
      v-if="isError || isWarning || isInfo || isCheckRequired"
      append-to-body
      multilined
      position="is-right"
      type="is-dark"
    >
      <span
        class="tag"
        :class="{ 'is-danger': isError, 'is-warning': isWarning || isCheckRequired, 'is-info': isInfo }"
      >
        {{ item.status | format }}
      </span>
      <template v-slot:content>
        {{ item | detailedFormat }}
      </template>
    </b-tooltip>
    <span v-else class="tag" :class="{ 'is-danger': isError, 'is-warning': isWarning, 'is-info': isInfo }">
      {{ item.status | format }}
    </span>
  </span>
</template>

<script>
  import StatusInfo from './StatusInfo';

  const STATUS_MAPPING = {
    error: 'Niet volledig',
    incomplete: 'Niet volledig',
    ready: 'Klaar voor verwerken',
    warning: 'Klaar voor verwerken',
    label: 'Niet geprint',
    printed: 'Geprint',
    transit: 'Onderweg naar sorteercentrum',
    depot: 'In sorteercentrum',
    courier: 'Chauffeur is onderweg',
    delivered: 'Bezorgd',
    blocked: 'Land geblokkeerd',
    picked_up_servicepoint: 'Opgehaald servicepunt',
    delivered_servicepoint: 'Bezorgd servicepunt',
    delivery_failure: 'Aflevering mislukt',
    return_from_delivery: 'Aflevering retour',
    check_required: 'Controleer zending'
  };
  const ERROR_STATUSUS = ['error', 'incomplete'];
  const WARNING_STATUSES = ['warning'];
  const INFO_STATUSES = ['info'];
  const CHECK_REQUIRED_STATUSES = ['check_required']; // take action the right phrasing?

  export default {
    components: { StatusInfo },
    props: {
      item: Object,
      type: {
        type: String,
        default: 'property'
      },
      noClass: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      rawErrorStatus() {
        return this.item.error_status ? this.item.error_status : this.item.status;
      },
      isError() {
        return ERROR_STATUSUS.includes(this.rawErrorStatus);
      },
      isWarning() {
        return WARNING_STATUSES.includes(this.rawErrorStatus);
      },
      isInfo() {
        return INFO_STATUSES.includes(this.rawErrorStatus);
      },
      isCheckRequired() {
        return CHECK_REQUIRED_STATUSES.includes(this.rawErrorStatus);
      }
    },
    filters: {
      format: function (value) {
        const s = String(value).toLowerCase();
        if (STATUS_MAPPING[s]) return STATUS_MAPPING[s];
        else if (value) return value;
        return 'Onbekend';
      },
      detailedFormat: function (item) {
        // collect info messages
        let infoMessages = [];
        const info_object_array = item.status_info.filter(o => o.status == 'info');

        info_object_array.forEach(element => {
          infoMessages.push(element.message);
        });
        const orderMapping = {
          incomplete: 'Er kan geen label worden aangemaakt. Onderneem actie. Zie order voor details',
          error: 'Er kan geen label worden aangemaakt. Onderneem actie. Zie order voor details',
          warning: 'Er ging iets mis. Er kan nog steeds een label worden aangemaakt. Zie order voor details.',
          check_required:
            'Minstens één van de verzendregels kon niet worden uitevoerd. Er kan nog steeds een label worden aangemaakt. Zie order voor details.',
          info: infoMessages.join(' ')
        };
        const labelMapping = {
          // TODO is this even necessary? Can't we just use one status message for every error type?
          // TODO new text for info?
          incomplete: 'Er gings iets fout tijdens het zenden. Zie status in zendingdetails.',
          warning: 'Er is iets aan de hand met de label en/of met de bijbehorende order. Zie status in zendingdetails',
          info: 'Er is iets aan de hand met de label en/of met de bijbehorende order. Zie status in zendingdetails'
        };
        if (item.type === 'order') return orderMapping[item.status];
        if (item.type === 'label') return labelMapping[item.error_status];
      }
    }
  };
</script>
