const DEFAULT_SETTINGS = {
  label_settings: {
    mode: 'pdf',
    format: 'a6',
  },
};

export default {
  data() {
    return {
      preferences: this.getPreferencesFromLocalStorage(),
    };
  },

  methods: {
    getPreferencesFromLocalStorage() {
      return JSON.parse(localStorage.getItem('preferences')) || DEFAULT_SETTINGS;
    },
    setPreferencesToLocalStorage() {
      localStorage.setItem('preferences', JSON.stringify(this.preferences));
    },
  },

  watch: {
    preferences: {
      handler() {
        this.setPreferencesToLocalStorage();
      },
      deep: true,
    },
  },
};
