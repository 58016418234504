<template>
  <div id="map-modal-container" class="tw-fixed tw-z-50 tw-inset-0 md:tw-bg-black/90">
    <font-awesome-icon
      id="map-close-icon"
      class="tw-absolute tw-hidden md:tw-block tw-text-white tw-text-2xl tw-z-20 tw-top-5 tw-right-5 tw-cursor-pointer"
      icon="times-circle"
      @click="emit('close')"
    />
    <div
      id="map-modal"
      class="tw-absolute tw-bg-white tw-group/map-state tw-inset-0 md:tw-inset-10 tw-overflow-hidden tw-grid tw-grid-rows-[auto,1fr,auto] md:tw-grid-cols-[400px,auto] md:[grid-template-areas:'search_map'_'list_map'_'buttons_map'] [grid-template-areas:'search'_'list-or-map'_'buttons']"
      :class="{
        'tw-absolute tw-inset-0 md:tw-inset-10 tw-group tw-rounded-2xl tw-overflow-hidden tw-grid tw-grid-cols-1 md:tw-grid-cols-[400px,auto]': false,
        'view-list': view === 'list',
        'view-map': view === 'map',
      }"
    >
      <template v-if="loading_pickup_points">
        <div
          class="tw-grid tw-absolute tw-inset-0 tw-place-items-center tw-bg-gray-100 tw-w-full tw-h-full tw-font-bold tw-text-2xl"
        >
          <font-awesome-icon class="tw-w-16 tw-h-16 tw-animate-spin" icon="spinner" />
        </div>
      </template>
      <template v-else-if="pickup_points_by_courier.length">
        <header
          id="search-area"
          class="tw-px-4 [grid-area:search] tw-bg-white tw-py-6 !tw-border-b-2 tw-border-0 tw-border-solid tw-border-primary"
        >
          <div class="field has-addons level-item">
            <div class="control tw-w-full">
              <label class="is-sr-only" for="search-field">Zoek afhaalpunt</label>
              <input
                id="search-field"
                v-model="search"
                class="input"
                placeholder="Zoek afhaalpunt"
                type="search"
                @keydown.enter="performSearch"
              />
            </div>
            <div class="control">
              <button class="button" type="button" @click="performSearch">
                <span class="icon">
                  <font-awesome-icon fixed-width icon="search" />
                </span>
              </button>
            </div>
          </div>
        </header>
        <section class="md:[grid-area:map] [grid-area:list-or-map] group-[.view-list]/map-state:tw-hidden md:!tw-block">
          <PickupMap
            class="tw-hidden group-[.view-map]/map-state:tw-block group-[.view-list]/map-state:tw-hidden md:!tw-block"
          />
        </section>

        <section
          id="locations-area"
          class="tw-grow tw-bg-white/80 tw-overflow-y-auto [grid-area:list-or-map] md:[grid-area:list] group-[.view-map]/map-state:tw-hidden md:!tw-block"
        >
          <PickupList />
        </section>

        <footer
          class="grid-area:buttons tw-flex tw-justify-between tw-items-center tw-bg-white tw-p-4 tw-border-t-2 tw-border-0 tw-border-solid tw-border-primary"
        >
          <div class="tw-flex tw-grow md:tw-justify-evenly tw-gap-4 tw-items-center">
            <b-button @click="emit('close')">Annuleren</b-button>
            <b-button :disabled="!selectedLocation" @click="setConfirmedLocation(selectedLocation)">
              Bevestigen
            </b-button>
          </div>
          <b-button class="md:tw-hidden tw-inline-flex" native-type="button" @click="toggleView">
            <font-awesome-icon fixed-width :icon="view === 'list' ? 'list' : 'map'" />
          </b-button>
        </footer>
      </template>
      <template v-else>
        <div
          class="tw-absolute tw-bg-gray-100 tw-font-bold tw-grid tw-h-full tw-inset-0 tw-place-items-center tw-text-2xl"
        >
          Er zijn geen afleverpunten gevonden.
        </div>
      </template>
    </div>
  </div>
</template>

<script setup>
  import { ref, computed, onMounted, provide, inject, onUnmounted } from 'vue';
  import PickupList from '@/components/form/pickup/PickupList.vue';
  import PickupMap from '@/components/form/pickup/PickupMap.vue';
  import { api } from '@/utils';

  const emit = defineEmits(['close']);

  const order = inject('order').value;
  const setConfirmedLocation = inject('setConfirmedLocation');
  const selectedLocation = inject('selectedLocation');

  const pickup_points_by_courier = ref([]);
  const search = ref('');
  const loading_pickup_points = ref(true);
  const view = ref('map');

  onUnmounted(() => {
    document.documentElement.style.overflow = 'auto';
  });

  onMounted(async () => {
    document.documentElement.style.overflow = 'hidden';
    // fetch pickup points

    pickup_points_by_courier.value = await api.getPoints({
      country_code: order.country,
      street: order.address_1,
      courier_id: order.shipment.courier_id,
      zip_code: order.zipcode,
      city: order.city,
    });
    loading_pickup_points.value = false;
  });

  const performSearch = async () => {
    const response = await api.getPoints({ search: search.value });

    let result = [];
    response.forEach(item => {
      let courier = item.courier;

      let locations = item.locations;

      let existing = result.find(r => r.courier.service_class === courier.service_class);
      if (existing) {
        // combine locations with unique id
        existing.locations = [...new Map([...existing.locations, ...locations].map(item => [item.id, item])).values()];
      } else {
        result.push({ courier, locations });
      }
    });
    pickup_points_by_courier.value = result;
  };

  const toggleView = () => {
    view.value = view.value === 'list' ? 'map' : 'list';
  };

  provide(
    'pickup_points_by_courier',
    computed(() => pickup_points_by_courier.value),
  );
</script>
