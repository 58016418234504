import store from '@/store';

function handleAuthenticated(next) {
  if (store.state.account.user.is_first_login) {
    store.commit('account/setFirstLogin', false);
    return next({ name: 'first-login' });
  } else return next();
}

export async function authGuard(to, from, next) {
  // Handle onboard and makes sure we're logged in
  const needsLogin = !store.getters['auth/isAuthenticated']();
  if (to.query.referer === 'redirect' && needsLogin) return next({ name: 'onboard' });
  else if (needsLogin) await store.dispatch('auth/login');

  // Make sure we've got a user
  if (store.state.account.user === null) {
    return next({ name: 'loading', params: { to } });
  } else if (store.state.account.user) return handleAuthenticated(next);
  else return next(false);
}
