import { formatStatus, getMoment, pluralize } from './functions';

export default {
  install(Vue) {
    // Filter to format timestamp values according to format.
    Vue.filter('formatTimestamp', (value, format = 'YYYY-MM-DD HH:mm') => {
      if (!value) return '';
      return getMoment(value).format(format);
    });

    Vue.filter('pluralize', pluralize);

    Vue.filter('formatStatus', formatStatus);

    // Filter to format an integer as a 2 decimal euro amount: (122 -> '€ 1.22')
    Vue.filter('formatMoney', (value, currency = 'EUR') => {
      if (value == null) return '';
      return new Intl.NumberFormat('nl-NL', { style: 'currency', currency }).format(value);
    });

    Vue.filter('humanizeDate', (value, options) => {
      if (!value) return '';
      if (!(value instanceof Date)) value = new Date(value);
      return value.toLocaleDateString('nl-NL', options);
    });

    Vue.filter('humanizeTime', (value, options) => {
      if (!value) return '';
      if (!(value instanceof Date)) value = new Date(value);
      return value.toLocaleTimeString('nl-NL', options);
    });

    Vue.filter('humanizeTimestamp', (value, format = 'full') => {
      if (!value) return '';
      if (!(value instanceof Date)) value = new Date(value);
      let options = {};
      if (format === 'full' || format === 'day') {
        options.weekday = 'long';
        options.month = 'long';
        options.day = 'numeric';
        if (new Date().getFullYear() != value.getFullYear()) options.year = 'numeric';
      }
      if (format === 'full' || format === 'time') {
        options.hour = 'numeric';
        options.minute = 'numeric';
      }
      if (format === 'time') return value.toLocaleTimeString('nl-NL', options);
      return value.toLocaleDateString('nl-NL', options);
    });
  }
};
