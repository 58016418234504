<template>
  <span class="property" :class="{ 'has-copy': copyValue }">
    <span>
      <slot></slot>
    </span>
    <b-button v-if="copyValue" class="copy-button" icon-right="copy" size="is-small" @click="copy"></b-button>
  </span>
</template>

<script>
  import { copyTextToClipboard } from '@/utils/functions.js';
  export default {
    props: {
      copyValue: null // Can be any type, we'll convert it to string
    },
    methods: {
      copy() {
        if (this.copyValue) {
          copyTextToClipboard(this.copyValue.toString());
          this.$buefy.toast.open({
            duration: 1500,
            message: 'Waarde gekopieerd',
            type: 'is-success'
          });
        }
      }
    }
  };
</script>
