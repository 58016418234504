<template>
  <div>
    <base-tab
      item-name="bestelling"
      item-name-plural="bestellingen"
      :loadView="loadView"
      :updateView="updateView"
      :view="view"
      @select="selectedOrders = $event"
      @sort="sort = $event"
    >
      <template #controls>
        <div class="level-item">
          <b-field class="has-dropdown">
            <b-button
              class="level-item responsive-button"
              :disabled="isUpdating"
              icon-left="sync"
              :loading="isUpdating"
              @click="update"
            >
              Update
            </b-button>
            <!-- <p class="control">
                            <b-button
                                class="level-item responsive-button"
                                icon-left="sync"
                                @click="update"
                                :disabled="isUpdating"
                                :loading="isUpdating"
                            >
                                Update
                            </b-button>
                        </p>
                        <p class="control">
                            <b-dropdown>
                                <button
                                    class="button responsive-button"
                                    slot="trigger"
                                    :disabled="isUpdating"
                                    :loading="isUpdating"
                                >
                                    <b-icon icon="chevron-down"></b-icon>
                                </button>

                                <b-dropdown-item
                                    aria-role="listitem"
                                    @click="forceUpdate"
                                    :disabled="isUpdating"
                                    :loading="isUpdating"
                                >
                                    <b-icon icon="sync"></b-icon>
                                    Forceer volledige update
                                </b-dropdown-item>
                            </b-dropdown>
                        </p> -->
          </b-field>
        </div>
        <div class="level-item">
          <b-field class="has-dropdown">
            <p class="control">
              <b-dropdown :disabled="isUpdating">
                <template #trigger>
                  <b-button
                    class="button responsive-button"
                    icon-right="chevron-down"
                    label="Meer acties…"
                    :loading="isUpdating"
                  >
                  </b-button>
                </template>

                <b-dropdown-item
                  aria-role="listitem"
                  :disabled="isUpdating"
                  :loading="isUpdating"
                  style="display: flex; gap: 8px; align-items: center"
                  @click="generatePackingLists"
                >
                  <font-awesome-icon class="is-size-5" fixed-width icon="file-arrow-down" />
                  Pakbonnen downloaden
                </b-dropdown-item>

                <b-dropdown-item
                  aria-role="listitem"
                  :disabled="isUpdating"
                  :loading="isUpdating"
                  style="display: flex; gap: 8px; align-items: center"
                  @click="deactivateBulkOrders"
                >
                  <font-awesome-icon class="is-size-5" fixed-width icon="trash" />
                  Orders verwijderen
                </b-dropdown-item>
              </b-dropdown>
            </p>
          </b-field>
        </div>
      </template>
      <template #table>
        <b-table-column v-slot="props" cell-class="is-vcentered" field="webshop" label="Shop" sortable>
          <web-shop :item="props.row" />
        </b-table-column>
        <b-table-column
          v-slot="props"
          cell-class="is-vcentered is-truncated is-15"
          field="order_number"
          label="Ordernummer"
          numeric
          sortable
        >
          <property class="has-tabular-nums" :copy-value="props.row.order_number">{{ props.row.order_number }}</property>
        </b-table-column>
        <b-table-column v-slot="props" cell-class="is-vcentered" field="status" label="Status" sortable>
          <status :item="props.row" type="tag" />
        </b-table-column>
        <b-table-column v-slot="props" cell-class="is-vcentered" field="order_status" label="Shop status" sortable>
          <span v-if="props.row.order_status" class="tag">{{ props.row.order_status }}</span>
        </b-table-column>
        <b-table-column v-slot="props" cell-class="is-vcentered is-truncated" field="name" label="Naam" sortable>
          <property :copy-value="props.row.name">{{ props.row.name }}</property>
        </b-table-column>
        <b-table-column
          v-slot="props"
          cell-class="is-vcentered is-truncated is-25"
          field="shipment_type"
          label="Verzendmethode"
          sortable
        >
          <courier format="short" :item="props.row" />
        </b-table-column>
        <b-table-column v-slot="props" cell-class="is-vcentered" field="country" label="Land" sortable>
          <country flag format="short" :value="props.row.country"></country>
        </b-table-column>
        <b-table-column v-slot="props" cell-class="is-vcentered" field="order_date" label="Orderdatum" numeric sortable>
          {{ getOrderTimestamp(props.row) | humanizeDate({ day: 'numeric', month: 'short', year: 'numeric' }) }}
        </b-table-column>
        <b-table-column v-slot="props" cell-class="is-vcentered buttons is-right">
          <b-button
            aria-label="aanpassen"
            class="is-small"
            tag="router-link"
            title="Aanpassen"
            :to="{ name: 'order', params: { orderId: props.row.id } }"
          >
            <font-awesome-icon class="icon" fixed-width icon="pen" />
          </b-button>
          <b-dropdown append-to-body position="is-bottom-left">
            <template #trigger>
              <button aria-label="acties" class="button is-small" title="acties">
                <font-awesome-icon class="icon" fixed-width icon="ellipsis-v" />
              </button>
            </template>

            <b-dropdown-item
              aria-role="listitem"
              style="display: flex; align-items: center; gap: 8px"
              @click="deactivateOrder(props.row)"
            >
              <font-awesome-icon class="is-size-5" fixed-width icon="copy" />
              Verwijderen
            </b-dropdown-item>

            <b-dropdown-item
              aria-role="listitem"
              style="display: flex; gap: 8px; align-items: center"
              @click="newOrderFromOrder(props.row)"
            >
              <font-awesome-icon class="is-size-5" fixed-width icon="copy" />
              Dupliceren naar bestellingen
            </b-dropdown-item>
          </b-dropdown>
        </b-table-column>
      </template>
      <template #table-empty>
        <p class="has-text-centered block">Geen bestellingen gevonden</p>
        <banner
          v-if="activeShops.length === 0 && !view.isLoading"
          bannerText="Er zijn nog geen webshops gekoppeld aan dit account. Ga naar instellingen, of druk op de onderstaande knop om ze in te stellen."
          buttonText="Koppel je eerste webshop"
          href="/settings/services/shop"
          type="is-warning"
        ></banner>
        <banner
          v-if="activeShops.length > 0 && !view.isLoading"
          bannerText="Hoera, alle bestellingen zijn verwerkt"
          type="is-success"
        ></banner>
      </template>
    </base-tab>
  </div>
</template>

<script>
  import { mapGetters, mapState } from 'vuex';
  import BaseTab from './BaseTab';
  import Banner from '@/components/Banner.vue'; // TODO kijk hiernaar
  import OrderMixin from '@/components/mixins/OrderMixin.js';
  import Courier from '@/components/properties/Courier';
  import Country from '@/components/properties/Country';
  import Property from '@/components/properties/Property';
  import Status from '@/components/properties/Status';
  import WebShop from '@/components/properties/WebShop';
  import { awaitIfPromise, getMoment, reportLabelError, reportLabelWarning } from '@/utils/functions';
  import { AbortPrintException, ApiError } from '@/utils/errors';

  export default {
    components: { Banner, BaseTab, WebShop, Property, Courier, Country, Status },
    mixins: [OrderMixin],
    props: {
      viewName: {
        type: String,
        default: 'orderTab',
      },
      orderFilter: {
        type: Object,
      },
    },
    data() {
      return {
        isUpdating: false,
        isCreatingLabel: false,
        selectedOrders: [],
        formats: ['a4'],
        sort: null,
      };
    },
    computed: {
      ...mapState({
        organisation: state => state.account.organisation,
        labelCount: state => state.account.labelCount || 0,
        view(state) {
          return state.order.views[this.viewName];
        },
        activeShops(state) {
          return state['shop'].all.filter(o => o.is_active).sort((a, b) => a.id.localeCompare(b.id));
        },
      }),
      ...mapGetters({
        printerClientIsReady: 'app/printerClientIsReady',
      }),
    },
    methods: {
      updateView() {
        this.$store.dispatch('order/updateView', { view: this.viewName });
      },
      loadView(page, filter) {
        this.$store.dispatch('order/loadView', {
          view: this.viewName,
          filter: {
            ...filter,
            ...this.orderFilter,
            has_shipments: false,
            page,
          },
        });
      },
      async update() {
        this.isUpdating = true;
        try {
          await this.$store.dispatch('order/reloadViews', { silent: false });
        } finally {
          this.isUpdating = false;
        }
      },
      async forceUpdate() {
        this.isUpdating = true;
        try {
          await this.$store.dispatch('order/update');
        } finally {
          this.isUpdating = false;
        }
      },

      async createLabels(callback, force = false) {
        this.isCreatingLabel = true;
        try {
          const orderIds = this.selectedOrders.map(i => i.id);

          const result = await this.$store.dispatch('label/createLabels', { orderIds, force });

          if (result.errors.length > 0) reportLabelError(result);

          const labelIds = orderIds
            .map(id => result.data.find(label => label.order_id === id)?.id)
            .filter(label => !!label);

          return awaitIfPromise(callback({ labelIds }));
        } catch (error) {
          if (error instanceof ApiError && error.response.status === 'warning') {
            reportLabelWarning(error.response, {
              cancelText: 'Annuleren',
              confirmText: 'Labels aanmaken',
              onConfirm: async () => {
                await this.createLabels(callback, true);
              },
            });
            throw new AbortPrintException();
          } else if (error instanceof ApiError) {
            reportLabelError(error.response);
            throw new AbortPrintException();
          } else {
            // TODO: Report
            throw error;
          }
        } finally {
          // TODO: Maybe move to store/label/createLabels
          await this.$store.dispatch('account/getLabelCount');
          this.isCreatingLabel = false;
        }
      },
      confirmBillingReport() {
        this.$router.push({ name: 'collection' });
      },
      deactivateOrder(order) {
        this.$buefy.dialog.confirm({
          title: 'Order verwijderen?',
          message: `Weet je zeker dat je deze order (ordernummer ${order.order_number}) wilt verwijderen? Dit kan niet ongedaan gemaakt worden.`,
          cancelText: 'Annuleren',
          confirmText: 'Verwijderen',
          type: 'is-danger',
          hasIcon: true,
          onConfirm: async () => {
            await this.$store.dispatch('order/updateOrder', {
              id: order.id,
              is_active: false,
            });
            await this.$store.dispatch('order/reloadViews', { silent: false });
            this.$buefy.toast.open('Order verwijderd!');
          },
        });
      },
      deactivateBulkOrders() {
        const orderIds = this.selectedOrders.map(i => i.id);
        const orderNumbers = this.selectedOrders.map(i => i.order_number);
        this.$buefy.dialog.confirm({
          title: 'Order verwijderen?',
          message: `Weet je zeker dat je de orders ${orderNumbers} wil verwijderen? Dit kan niet ongedaan worden`,
          cancelText: 'Annuleren',
          confirmText: 'Verwijderen',
          type: 'is-danger',
          hasIcon: true,
          onConfirm: async () => {
            await this.$store.dispatch('order/updateBulkOrders', {
              order_ids: orderIds,
              action: 'deactivate',
            });
            this.$store.dispatch('order/reloadViews', { silent: false });
            this.$buefy.toast.open('Orders verwijderd!');
          },
        });
      },
      getOrderTimestamp(order) {
        if (order.order_date) return getMoment(order.order_date);
        return getMoment(order.created_at);
      },
    },
  };
</script>
