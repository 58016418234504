<template>
  <b-field>
    <p class="control">
      <button
        class="button !tw-p-4 tw-flex tw-items-center tw-gap-2"
        :class="{
          'is-loading': isLoading,
        }"
        :disabled="isLoading || (!hasLabelIds && !hasOrderIds)"
        @click="handlePrimaryClick"
      >
        <template v-if="printerClientIsReady && preferences.label_settings.mode === 'printer'">
          <font-awesome-icon fixed-width icon="print" />
          <span class="tw-sr-only sm:tw-not-sr-only">Printen</span>
        </template>
        <template v-else>
          <font-awesome-icon fixed-width icon="download" />
          <span class="tw-sr-only sm:tw-not-sr-only">
            {{ preferences.label_settings.mode === 'printer' ? 'Printen' : 'Downloaden' }}
          </span>
        </template>
      </button>
    </p>
    <p class="control">
      <button
        v-if="context !== 'order'"
        class="button !tw-p-4 tw-flex tw-items-center tw-gap-1"
        :class="{
          'is-loading': isLoading,
        }"
        :disabled="isLoading || (!hasOrderIds && !hasLabelIds)"
        title="Extra label aanmaken"
        @click="handleSecondaryClick"
      >
        <font-awesome-icon fixed-width icon="plus" />
        Extra label
      </button>
    </p>

    <p class="control">
      <!--<editor-fold desc="Position Menu Button">-->
      <button
        v-if="preferences.label_settings.format === 'a4'"
        class="button !tw-p-4 tw-flex tw-items-center tw-gap-2"
        title="Indeling van de eerste pagina"
        @click="isShowingFirstPageSettings = true"
      >
        <span class="tw-sr-only"> Indeling van de eerste pagina </span>
        <div class="tw-grid tw-grid-cols-2 tw-gap-0.5 tw-w-5 tw-h-5">
          <div
            class="tw-border tw-aspect-square tw-border-solid tw-border-black tw-rounded-sm"
            :class="{ 'tw-bg-black': confirmedPositions.upperLeft }"
          ></div>
          <div
            class="tw-border tw-aspect-square tw-border-solid tw-border-black tw-rounded-sm"
            :class="{ 'tw-bg-black': confirmedPositions.upperRight }"
          ></div>
          <div
            class="tw-border tw-aspect-square tw-border-solid tw-border-black tw-rounded-sm"
            :class="{ 'tw-bg-black': confirmedPositions.lowerLeft }"
          ></div>
          <div
            class="tw-border tw-aspect-square tw-border-solid tw-border-black tw-rounded-sm"
            :class="{ 'tw-bg-black': confirmedPositions.lowerRight }"
          ></div>
        </div>
      </button>
      <!--</editor-fold>-->
    </p>

    <p class="control">
      <button class="button !tw-p-4" title="Label instellingen" @click="isShowingPrinterSettings = true">
        <font-awesome-icon fixed-width icon="gear" />
        <span class="tw-sr-only">Label instellingen</span>
      </button>
    </p>

    <!--<editor-fold desc="First Page Settings">-->
    <b-modal :active.sync="isShowingFirstPageSettings" has-modal-card>
      <div class="modal-card">
        <form @submit.prevent="submitPosition">
          <header class="modal-card-head">
            <p class="modal-card-title">Indeling van de eerste pagina</p>
            <button class="delete" type="button" @click="closeFirstPageSettings" />
          </header>

          <section class="modal-card-body">
            <p>
              Hier kun je aangeven op welke plaatsen van de eerst volgende pagina de labels geplaatst moeten worden. De
              overige labels worden niet op deze pagina geplaatst, maar gaan direct naar de volgende pagina. De labels
              worden van links naar rechts en van boven naar beneden geplaatst. Je kunt meerdere posities selecteren.
            </p>
            <br />
            <p>
              <strong>Let op:</strong> Deze instelling schakelt zichzelf uit zodra je begint met downloaden of printen.
            </p>
            <div class="tw-grid tw-grid-cols-[1fr,1fr] tw-gap-4 tw-aspect-square tw-w-2/3 md:tw-w-1/2 tw-p-4">
              <!--<editor-fold desc="Position Buttons">-->
              <button
                class="tw-bg-zinc-100 tw-cursor-pointer tw-group tw-border-4 tw-border-dotted tw-aspect-[1/1.414] tw-place-items-center tw-grid"
                type="button"
                @click="handlePositionClick(POSITIONS.upperLeft)"
              >
                <font-awesome-icon
                  class="has-text-success tw-transition-opacity tw-relative tw-col-start-1 tw-row-start-1 tw-opacity-0 tw-size-1/2"
                  :class="{
                    'tw-opacity-100': positions.upperLeft,
                    'group-hover:tw-opacity-50': positions.upperLeft,
                  }"
                  icon="check-circle"
                />
                <font-awesome-icon
                  class="tw-col-start-1 tw-transition-opacity tw-relative tw-size-3/4 tw-z-10 tw-opacity-0 tw-row-start-1"
                  :class="{
                    'group-hover:tw-opacity-60': positions.upperLeft,
                  }"
                  icon="times"
                />
              </button>

              <button
                class="tw-bg-zinc-100 tw-cursor-pointer tw-group tw-border-4 tw-border-dotted tw-aspect-[1/1.414] tw-place-items-center tw-grid"
                type="button"
                @click="handlePositionClick(POSITIONS.upperRight)"
              >
                <font-awesome-icon
                  class="has-text-success tw-transition-opacity tw-relative tw-col-start-1 tw-row-start-1 tw-opacity-0 tw-size-1/2"
                  :class="{
                    'tw-opacity-100': positions.upperRight,
                    'group-hover:tw-opacity-50': positions.upperRight,
                  }"
                  icon="check-circle"
                />
                <font-awesome-icon
                  class="tw-col-start-1 tw-transition-opacity tw-relative tw-size-3/4 tw-z-10 tw-opacity-0 tw-row-start-1"
                  :class="{
                    'group-hover:tw-opacity-60': positions.upperRight,
                  }"
                  icon="times"
                />
              </button>

              <button
                class="tw-bg-zinc-100 tw-cursor-pointer tw-group tw-border-4 tw-border-dotted tw-aspect-[1/1.414] tw-place-items-center tw-grid"
                type="button"
                @click="handlePositionClick(POSITIONS.lowerLeft)"
              >
                <font-awesome-icon
                  class="has-text-success tw-transition-opacity tw-relative tw-col-start-1 tw-row-start-1 tw-opacity-0 tw-size-1/2"
                  :class="{
                    'tw-opacity-100': positions.lowerLeft,
                    'group-hover:tw-opacity-50': positions.lowerLeft,
                  }"
                  icon="check-circle"
                />
                <font-awesome-icon
                  class="tw-col-start-1 tw-transition-opacity tw-relative tw-size-3/4 tw-z-10 tw-opacity-0 tw-row-start-1"
                  :class="{
                    'group-hover:tw-opacity-60': positions.lowerLeft,
                  }"
                  icon="times"
                />
              </button>

              <button
                class="tw-bg-zinc-100 tw-cursor-pointer tw-group tw-border-4 tw-border-dotted tw-aspect-[1/1.414] tw-place-items-center tw-grid"
                type="button"
                @click="handlePositionClick(POSITIONS.lowerRight)"
              >
                <font-awesome-icon
                  class="has-text-success tw-transition-opacity tw-relative tw-col-start-1 tw-row-start-1 tw-opacity-0 tw-size-1/2"
                  :class="{
                    'tw-opacity-100': positions.lowerRight,
                    'group-hover:tw-opacity-50': positions.lowerRight,
                  }"
                  icon="check-circle"
                />
                <font-awesome-icon
                  class="tw-col-start-1 tw-transition-opacity tw-relative tw-size-3/4 tw-z-10 tw-opacity-0 tw-row-start-1"
                  :class="{
                    'group-hover:tw-opacity-60': positions.lowerRight,
                  }"
                  icon="times"
                />
              </button>
              <!--</editor-fold>-->
            </div>
          </section>

          <footer class="modal-card-foot">
            <button class="button is-success" type="submit">Bevestigen</button>
            <button class="button" type="button" @click="closeFirstPageSettings">Annuleren</button>
          </footer>
        </form>
      </div>
    </b-modal>
    <!--</editor-fold>-->

    <!--<editor-fold desc="Label Settings">-->
    <b-modal :active.sync="isShowingPrinterSettings" has-modal-card>
      <div class="modal-card tw-w-auto">
        <form @submit.prevent="updateLabelSettings">
          <header class="modal-card-head">
            <p class="modal-card-title">Label instellingen</p>
            <button class="delete" type="button" @click="closeLabelSettings" />
          </header>
          <section class="modal-card-body">
            <div class="tw-flex md:tw-flex-row tw-flex-col tw-gap-4 md:tw-gap-8">
              <b-field label="Papierformaat">
                <b-radio-button v-model="form.format" native-value="a4" type="is-success"
                  >A4 (4 per pagina)
                </b-radio-button>
                <b-radio-button v-model="form.format" native-value="a6" type="is-success"
                  >A6 (1 per pagina)
                </b-radio-button>
              </b-field>
              <b-field label="Modus">
                <b-radio-button v-model="form.mode" native-value="pdf" type="is-success">Download</b-radio-button>
                <b-radio-button v-if="printerClientIsReady" v-model="form.mode" native-value="printer" type="is-success"
                  >Printer
                </b-radio-button>
                <p v-else class="control">
                  <b-button @click="goToPrinterSettings">Printer instellen</b-button>
                </p>
              </b-field>
            </div>
          </section>
          <footer class="modal-card-foot">
            <button class="button is-success" type="submit">Opslaan</button>
            <button class="button" type="button" @click="closeLabelSettings">Annuleren</button>
          </footer>
        </form>
      </div>
    </b-modal>
    <!--</editor-fold>-->
  </b-field>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { printLabel, reportLabelError, reportLabelWarning } from '@/utils/functions';
  import { AbortPrintException, ApiError } from '@/utils/errors';
  import LabelSelectorModal from '@/components/modals/LabelSelectorModal.vue';
  import PreferencesMixin from '@/components/mixins/PreferencesMixin';

  const POSITIONS = {
    upperLeft: 'upperLeft',
    upperRight: 'upperRight',
    lowerLeft: 'lowerLeft',
    lowerRight: 'lowerRight',
  };

  export default {
    mixins: [PreferencesMixin],
    beforeMount() {
      this.reset();
    },
    props: {
      /**
       * The mode of the print controls
       * When in order mode, labels will be created before printing
       */
      context: {
        type: String,
        required: true,
        validator: value => ['order', 'label', 'scan'].includes(value),
      },
      labelIds: {
        type: Array,
        default: () => [],
      },
      orderIds: {
        type: Array,
        default: () => [],
      },
      parameters: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        POSITIONS,
        isLoading: false,
        form: null,
        positions: {
          upperLeft: false,
          upperRight: false,
          lowerLeft: false,
          lowerRight: false,
        },
        confirmedPositions: {
          upperLeft: false,
          upperRight: false,
          lowerLeft: false,
          lowerRight: false,
        },
        isShowingPrinterSettings: false,
        isShowingFirstPageSettings: false,
      };
    },
    computed: {
      ...mapGetters({
        printerClientIsReady: 'app/printerClientIsReady',
      }),
      hasOrderIds() {
        return this.orderIds?.length > 0;
      },
      hasLabelIds() {
        return this.labelIds?.length > 0;
      },
    },
    methods: {
      /** suspends the print process and shows a warning message, if the user confirms the process will continue and force label creation */
      async handleLabelWarning(error, orderIds) {
        return await new Promise(resolve => {
          reportLabelWarning(error.response, {
            cancelText: 'Annuleren',
            confirmText: 'Labels aanmaken',
            onCancel: () => {
              resolve([]);
            },
            onConfirm: async () => {
              try {
                resolve(await this.createOrderLabels({ orderIds, force: true }));
              } catch (error) {
                if (!(error instanceof AbortPrintException)) throw error;
              } finally {
                resolve([]);
              }
            },
          });
        });
      },

      async printOrDownload(labelIds) {
        if (labelIds.length === 0) return;
        return await printLabel({
          labelIds,
          target: this.printerClientIsReady ? this.preferences.label_settings.mode : 'pdf',
          format: this.preferences.label_settings.format,
          positions: this.confirmedPositions,
          ...this.parameters,
        });
      },
      /** This creates additional labels on top of the already existing labels and adds to order.shipment_ids */
      async createOrderLabels({ orderIds = [], force = false }) {
        try {
          const result = await this.$store.dispatch('label/createLabels', {
            orderIds: orderIds,
            force,
            scan: this.context === 'scan',
          });

          if (result.errors.length > 0) {
            void reportLabelError(result);
          }

          return orderIds.map(id => result.data.find(label => label.order_id === id)?.id).filter(label => !!label);
        } catch (error) {
          if (error instanceof ApiError) {
            if (error.response.status === 'warning') {
              return await this.handleLabelWarning(error, orderIds);
            } else {
              reportLabelError(error.response);
              return [];
            }
          } else if (!(error instanceof AbortPrintException)) {
            throw error;
          } else {
            return [];
          }
        }
      },

      async handleSecondaryClick() {
        this.isLoading = true;
        const labelIds = await this.createOrderLabels({ orderIds: this.orderIds });

        if (labelIds.length > 0) {
          await this.printOrDownload(labelIds);

          await this.$store.dispatch('account/getLabelCount');
          await this.$store.dispatch('order/reloadViews', { silent: false }, { root: true });
        }

        this.isLoading = false;
        this.confirmedPositions = {
          upperLeft: false,
          upperRight: false,
          lowerLeft: false,
          lowerRight: false,
        };
        this.positions = {
          upperLeft: false,
          upperRight: false,
          lowerLeft: false,
          lowerRight: false,
        };
      },

      async handlePrimaryClick() {
        this.isLoading = true;

        // when context is scan, notify the user if there are multiple labels
        // on the order and ask which ones to print
        // show modal with checkboxes and order numbers
        // where user can select which orders to print

        let labelIds = structuredClone(this.labelIds);

        // This should only happen in scan context as we are in the perspective of a single order.
        // On LabelTab.vue we can select different labels individually.
        if (this.context === 'scan' && labelIds.length > 1) {
          labelIds = await new Promise(resolve => {
            this.$buefy.modal.open({
              component: LabelSelectorModal,
              parent: this,
              props: {
                labelIds,
                onCancel: () => {
                  resolve([]);
                },
                onConfirm: async selectedLabelIds => {
                  resolve(selectedLabelIds);
                },
              },
            });
          });
        }

        if (this.context !== 'label' && this.orderIds.length > 0 && !this.labelIds.length) {
          labelIds = await this.createOrderLabels({ orderIds: this.orderIds });
        }

        if (labelIds.length > 0) {
          await this.printOrDownload(labelIds);

          await this.$store.dispatch('account/getLabelCount');
          await this.$store.dispatch('order/reloadViews', { silent: false }, { root: true });
        }

        this.isLoading = false;
        this.confirmedPositions = {
          upperLeft: false,
          upperRight: false,
          lowerLeft: false,
          lowerRight: false,
        };
        this.positions = {
          upperLeft: false,
          upperRight: false,
          lowerLeft: false,
          lowerRight: false,
        };
      },

      async reset() {
        this.form = structuredClone(this.preferences.label_settings);
      },
      closeLabelSettings() {
        this.reset();
        this.isShowingPrinterSettings = false;
      },

      closeFirstPageSettings() {
        this.isShowingFirstPageSettings = false;
        this.positions = this.confirmedPositions;
      },
      submitPosition() {
        this.confirmedPositions = this.positions;
        this.isShowingFirstPageSettings = false;
      },
      goToPrinterSettings() {
        this.isShowingPrinterSettings = false;
        setTimeout(() => {
          this.$router.push({ name: 'printer' });
        }, 250);
      },
      handlePositionClick(position) {
        console.log('before', this.positions[position]);
        this.positions[position] = !this.positions[position];
        console.log('after', this.positions[position]);
      },
      async updateLabelSettings() {
        this.preferences.label_settings = structuredClone(this.form);
        this.isShowingPrinterSettings = false;
      },
    },
  };
</script>
